import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import OutlinedButton from "./OutlinedButton";

export default function ConfirmDialog({
  promiseArgs,
  msg,
  handleNo,
  handleYes,
}) {
  if (!promiseArgs) {
    return null;
  }

  return (
    <Dialog maxWidth="xs" open={!!promiseArgs}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers>{msg}</DialogContent>
      <DialogActions>
        <OutlinedButton onClick={handleNo}>No</OutlinedButton>
        <OutlinedButton onClick={handleYes}>Yes</OutlinedButton>
      </DialogActions>
    </Dialog>
  );
}
