import React from "react";
import { Outlet } from "react-router-dom";
import NavigationDrawer from "../components/sidebar-navigation/NavigationDrawer";
import "../Styles/CustomStyles.css";
import { ThemeProvider } from "@mui/material";
import colorTheme from "../Utils/theme";
import LayoutWrapper from "../Utils/LayoutWrapper";

function DefaultLayout() {

  return (
    <div className="home__page h-screen">
      <div className="col-span-1">
        <NavigationDrawer />
      </div>
      <div className="col-[2_/_span_19]">
        <ThemeProvider theme={colorTheme}>
            <LayoutWrapper>
                <Outlet />
            </LayoutWrapper>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default DefaultLayout;
