import React, { useState } from 'react'
import TextInput from '../components/common/TextInput'
import { Autocomplete, InputAdornment } from '@mui/material'
import OutlinedButton from '../components/common/OutlinedButton'
import ColoredButton from '../components/common/ColoredButton'
import { TipsAndUpdates } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import FeedbackSnackbar from '../components/common/FeedbackSnackbar'
import LoadingBackdrop from '../components/common/LoadingBackdrop'
import { createAxiosInstance } from '../Utils/axiosConfig'

const AddEtfStrategy = () => {

    const location = useLocation();

    const [etfStrategyDetails, setEtfStrategyDetails] = useState(location?.state?.strategyDetails ?? {
        name: "",
        // funds: [],
        amount: 0,
        costavg: 0,
        takeprofit: 0
    })

    const [mode] = useState(location?.state?.mode);

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        setEtfStrategyDetails(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    // const handleFundsChange = (e, value) => {
    //     setEtfStrategyDetails(prev => ({
    //         ...prev,
    //         funds: value
    //     }))
    // }

    const handleCreate = async () => {
        try {
            setLoading(true);
            const axiosInstance = createAxiosInstance(true);

            const response = mode === 'edit' ? await axiosInstance.put('trades/etf/', {
                ...etfStrategyDetails,
                id: etfStrategyDetails.id,
                user: JSON.parse(localStorage.getItem("loggedInUser")).pk,
            }) : await axiosInstance.post('trades/etf/', {
                ...etfStrategyDetails,
                user: JSON.parse(localStorage.getItem("loggedInUser")).pk,
            })

            if (response.status === 200 || response.status === 201) {
                setSnackBarOpen(true);
                setDisplayMsg({
                    type: "success",
                    msg: mode === "edit" ? "ETF strategy edited successfully" : "ETF strategy created successfully",
                });
                setTimeout(() => {
                    navigate('/strategies');
                }, 1500);
            }

        } catch (err) {
            let msg = '';
            if (err.request.status === 404)
                msg = err.message;
            else if (err.request.status === 401 || err.request.status === 400) {
                Object.entries(err.response.data).forEach(([key, value]) => {
                    msg += key + ': ' + value + "\n";
                });
            } else {
                msg = "There was some error in creating this strategy"
            }

            setSnackBarOpen(true);
            setDisplayMsg({
                type: "error",
                msg: msg
            })
        } finally {
            setLoading(false);
        }
    }

    const handleCancel = () => {
        navigate('/strategies')
    }

    return (
        <div className="h-full flex flex-col">
            <h1 className="text-4xl font-medium text-gray-900 tracking-tight">Create ETF Strategy</h1>

            <div className="flex justify-between mt-16 w-full">
                <div className='flex flex-col gap-6 w-full'>
                    <div className="flex flex-col gap-2 w-1/2">
                        <label className="font-medium text-sm">
                            Strategy name<span className="text-red-700">*</span>
                        </label>
                        <TextInput
                            value={etfStrategyDetails.name}
                            name="name"
                            size="small"
                            fullWidth
                            placeholder="Enter strategy name"
                            autoComplete="off"
                            onChange={handleChange}
                        />
                    </div>
                    {/* 
                    <div className='flex flex-col gap-2 w-1/2'>
                        <label className="font-medium text-sm">
                            Select ETF Funds<span className="text-red-700">*</span>
                        </label>

                        <Autocomplete
                            multiple
                            value={etfStrategyDetails.funds}
                            openOnFocus={true}
                            size="small"
                            options={['ETF Fund 1', 'ETF Fund 2', 'ETF Fund 3', 'ETF Fund 4']}
                            onChange={handleFundsChange}
                            clearOnEscape
                            clearOnBlur
                            autoComplete={true}
                            renderInput={(params) => (
                                <TextInput {...params} placeholder="Select ETF Funds" />
                            )}
                        />
                    </div> */}

                    <div className="flex flex-col gap-2 w-1/2">
                        <label className="font-medium text-sm">
                            Total Investment Amount<span className="text-red-700">*</span>
                        </label>
                        <TextInput
                            value={etfStrategyDetails.amount}
                            name="amount"
                            size="small"
                            fullWidth
                            placeholder="Enter total investment amount"
                            autoComplete="off"
                            InputProps={{ startAdornment: <InputAdornment position='start'>Rs. </InputAdornment> }}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="flex flex-col gap-2 w-1/2">
                        <label className="font-medium text-sm">
                            Cost Average %<span className="text-red-700">*</span>
                        </label>
                        <TextInput
                            value={etfStrategyDetails.costavg}
                            name="costavg"
                            size="small"
                            fullWidth
                            type='number'
                            placeholder="Enter cost average %"
                            autoComplete="off"
                            InputProps={{ endAdornment: <InputAdornment position='end'>% </InputAdornment> }}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="flex flex-col gap-2 w-1/2">
                        <label className="font-medium text-sm">
                            Profit %<span className="text-red-700">*</span>
                        </label>
                        <TextInput
                            value={etfStrategyDetails.takeprofit}
                            name="takeprofit"
                            size="small"
                            fullWidth
                            type='number'
                            placeholder="Enter profit %"
                            autoComplete="off"
                            InputProps={{ endAdornment: <InputAdornment position='end'>% </InputAdornment> }}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className='py-6 px-9 bg-[#D9D9D9] bg-opacity-50'>
                    <div className='flex flex-col gap-7'>
                        <div className='flex gap-4 text-black items-center'>
                            <TipsAndUpdates sx={{ width: 32, height: 32 }} />
                            <span className='font-medium text-3xl'>Guidelines</span>
                        </div>

                        <ul className='list-disc list-inside'>
                            <li>Cost averaging involves regularly investing a fixed amount of money into ETFs, regardless of market fluctuations.</li>

                            <li className='mt-3'>By investing periodically, you avoid the risk of trying to time the market, which can be challenging.</li>

                            <li className='mt-3'>Total investment refers to the total amount to be invested as part of this strategy.</li>

                            <li className='mt-3'>Cost average % is the percentage in dip of the ETF price that would trigger a fresh purchase.</li>

                            <li className='mt-3'>Profit % is the percentage at which investments are to be sold strategically.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="flex gap-7 mt-auto">
                <OutlinedButton
                    variant="outlined"
                    size="small"
                    onClick={handleCancel}
                >
                    Cancel
                </OutlinedButton>
                <ColoredButton
                    variant="contained"
                    size="small"
                    onClick={handleCreate}
                >
                    {mode === "edit" ? "Save" : "Create"}
                </ColoredButton>
            </div>


            <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />

            {loading && <LoadingBackdrop loading={loading} />}

        </div>
    )
}

export default AddEtfStrategy