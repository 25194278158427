import { UploadFile } from "@mui/icons-material";
import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ColoredButton from "../components/common/ColoredButton";
import FeedbackSnackbar from "../components/common/FeedbackSnackbar";
import LoadingBackdrop from "../components/common/LoadingBackdrop";
import OutlinedButton from "../components/common/OutlinedButton";
import TextInput from "../components/common/TextInput";
import VisuallyHiddenInput from "../components/common/VisuallyHiddenInput";
import { createAxiosInstance } from "../Utils/axiosConfig";

function SettingsPage() {
  const [userDetails, setUserDetails] = useState({});

  const navigate = useNavigate();

  const [isError, setIsError] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const [helperText, setHelperText] = useState({
    firstNameHelperText: "",
    lastNameHelperText: "",
    emailHelperText: "",
  });

  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });

  const [loading, setLoading] = useState(false);

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const validationController = (event) => {
    const { name, value } = event.target;

    setIsError((prevData) => {
      return { ...prevData, [name]: validateUserInput(name, value) };
    });
  };

  const handleSaveClick = async () => {
    if(isError.firstName || isError.lastName || isError.email)
        return;

    setLoading(true);
    try {
        const axiosInstance = createAxiosInstance(true);
        const response = await axiosInstance.put('auth/user/', userDetails)
        if(response.status === 200) {
            setSnackBarOpen(true);
            setDisplayMsg({type: "success", msg: "Profile updated successfully"})
            localStorage.setItem("userDetails", JSON.stringify(response.data));
        }
    } catch(err) {
        setDisplayMsg({type: "error", msg: "Some error has occurred"})
        setSnackBarOpen(true)
    } finally {
        setLoading(false);
    }
  }

  const validateUserInput = (name, value) => {
    if (name === "email") {
      if (value === "") {
        setHelperText((prevData) => {
          return { ...prevData, emailHelperText: "Email is required" };
        });
        return true;
      } else if (!value.match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/)) {
        setHelperText((prevData) => {
          return { ...prevData, emailHelperText: "Invalid email format" };
        });
        return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    var _userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(_userDetails);
  }, []);

  return (
    <div className="flex flex-col gap-8 overflow-y-auto h-full">
      <h1 className="text-4xl font-medium text-gray-900 tracking-tight">Settings</h1>

      <div className="flex flex-col gap-5 overflow-y-auto">
        <div className="flex flex-col">
            <span className="text-lg font-medium">Profile Information</span>
            <span className="text-sm text-[#667085]">Update your profile information here.</span>
        </div>
        <Divider />

        <div className="grid grid-cols-2 w-[800px]">
            <span className="text-sm text-[#344054]">Name</span>
            <div className="space-x-6 flex">
                <TextInput name="first_name" variant="outlined" size="small" placeholder="First name" value={userDetails.first_name} onChange={handleChange} error={isError.firstName} helperText={helperText.firstNameHelperText} onBlur={validationController} />
                <TextInput name="last_name" variant="outlined" size="small" placeholder="Last name" value={userDetails.last_name} onChange={handleChange} error={isError.lastName} helperText={helperText.lastNameHelperText} onBlur={validationController} />
            </div>
        </div>
        <Divider />

        <div className="grid grid-cols-2 w-[800px]">
            <span className="text-sm text-[#344054]">Email Address</span>
            <TextInput name="email" variant="outlined" size="small" placeholder="Email Address" value={userDetails.email} onChange={handleChange} error={isError.email} helperText={helperText.emailHelperText} onBlur={validationController} />
        </div>
        <Divider />

        <div className="grid grid-cols-2 w-[800px]">
            <span className="text-sm text-[#344054]">Profile Picture</span>
            <ColoredButton component="label" variant="outlined">
                <div className="flex flex-col items-center gap-3 py-4 px-6">
                    <UploadFile />
                    <span className="text-[#363F72] font-semibold">Click to upload<span className="font-medium"> or drag and drop</span></span>
                    <span className="text-xs text-[#667085]">SVG, PNG, JPG or GIF (max. 800x400px)</span>
                </div>
                <VisuallyHiddenInput type="file" />
            </ColoredButton>
        </div>
        <Divider />

        <div className="grid grid-cols-2 w-[800px]">
            <span className="text-sm text-[#344054]">Password</span>
            <div className="gap-2 flex flex-col">
                <TextInput variant="outlined" size="small" placeholder="Current Password" />
                <TextInput variant="outlined" size="small" placeholder="New Password" />
            </div>
        </div>
        <Divider />

        <div className="flex gap-4">
            <OutlinedButton variant="outlined" size="small" onClick={() => navigate('/home')}>Cancel</OutlinedButton>
            <ColoredButton variant="contained" size="small" onClick={handleSaveClick}>Save</ColoredButton>
        </div>
      </div>
      
    {loading && <LoadingBackdrop loading={loading} />}

      
    {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />}
    </div>
  );
}

export default SettingsPage;
