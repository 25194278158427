import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useToken from './useToken';

const RequireAuth = () => {
    const { token } = useToken();
    const location = useLocation();

    return (
        token ? <Outlet /> : <Navigate to="/login" state={{from: location}} replace />
    )
}

export default RequireAuth