import { Alert, Snackbar } from '@mui/material';
import React from 'react';

const FeedbackSnackbar = ({ snackBarOpen, setSnackBarOpen, displayMsg }) => {

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            setSnackBarOpen(false);
            return;
        }

        setSnackBarOpen(false);
    }

    return (
        displayMsg.type != undefined &&
        <Snackbar
            open={snackBarOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
        >
            <Alert
                variant="filled"
                onClose={handleSnackbarClose}
                severity={displayMsg.type}
                sx={{ width: "100%" }}
            >
                {displayMsg.msg}
            </Alert>
        </Snackbar>
    )

}

export default FeedbackSnackbar;