import { ContentCopy } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import zerodhaBrokerSetup2 from "../assets/zerodha-broker-setup-2.png";
import zerodhaBrokerSetup from "../assets/zerodha-broker-setup.png";
import ColoredButton from "../components/common/ColoredButton";
import FeedbackSnackbar from "../components/common/FeedbackSnackbar";
import LoadingBackdrop from "../components/common/LoadingBackdrop";
import TextInput from "../components/common/TextInput";
import { createAxiosInstance } from "../Utils/axiosConfig";

function AddZerodhaBroker() {

    const location = useLocation();
    const [brokerDetails, setBrokerDetails] = useState(location.state?.accountDetails ? location.state?.accountDetails : {
        nickname: "",
        userid: "",
        api_key: "",
        api_secret: "",
    });

    const [mode] = useState(location.state?.mode === 'edit' ? 'edit' : 'create');

    const [redirectUrl, setRedirectUrl] = useState('');

    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;

        setBrokerDetails((prevData) => {
            return { ...prevData, [name]: value };
        });
    };

    const onSaveBtnClick = async () => {
        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            var response;
            if (mode === 'edit') {
                response = await axiosInstance.put('trades/zerodha/', {
                    ...brokerDetails,
                    user: JSON.parse(localStorage.getItem("loggedInUser")).pk
                });
            } else {
                response = await axiosInstance.post('trades/zerodha/', {
                    ...brokerDetails,
                    user: JSON.parse(localStorage.getItem("loggedInUser")).pk
                });
            }

            if (response.status === 201) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Broker account setup successfully", type: "success" });
            }
            else if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Broker account updated successfully", type: "success" });
            }

            setTimeout(() => {
                navigate("/brokerAccountsSetup");
            }, 1500);
        } catch (err) {
            let msg = '';
            if (err.request.status === 400) {
                err.response.data?.detail ? msg = err.response.data.detail
                    : Object.entries(err.response.data).forEach(([key, value]) => {
                        msg += key + ': ' + value + "\n";
                    });
            }
            else if (err.request.status === 401 || err.request.status === 404)
                msg = err.message;

            setSnackBarOpen(true);
            setDisplayMsg({ msg: msg, type: "error" });
        } finally {
            setLoading(false);
        }
    };

    const generateLinks = () => {
        var redirectUrl = process.env.REACT_APP_URL_STG;
        redirectUrl += 'zerodha/redirect/' + brokerDetails.userid + '/';
        setRedirectUrl(redirectUrl);
    }

    const copyRedirectUrl = () => {
        navigator.clipboard.writeText(redirectUrl);
        setSnackBarOpen(true);
        setDisplayMsg({ type: 'success', msg: 'Redirect url copied to clipboard' });
    }

    return (
        <div className="flex flex-col gap-8 overflow-y-auto h-full">
            <div className="flex flex-col gap-5 overflow-y-auto">
                <h1 className="text-4xl font-medium text-gray-900 tracking-tight">Create Zerodha broker account</h1>
                <h1>
                    Setup Zerodha TOTP if you haven't already. Find instructions to
                    setup TOTP here:{" "}
                    <Link
                        to="https://support.zerodha.com/category/your-zerodha-account/login-credentials/login-credentials-of-trading-platforms/articles/time-based-otp-setup"
                        target="_blank"
                    >
                        {" "}
                        <span className="text-primary font-medium underline">
                            Zerodha TOTP setup
                        </span>
                    </Link>
                </h1>
                <div className="w-2/6">
                    <h1 className="mb-2.5 font-semibold">
                        Enter an account nickname
                    </h1>
                    <TextInput
                        name="nickname"
                        value={brokerDetails.nickname}
                        fullWidth
                        size="small"
                        placeholder="Enter your account nickname"
                        onChange={handleChange}
                    />
                </div>
                <div className="w-2/6">
                    <h1 className="mb-2.5 font-semibold">
                        Enter your 6-digit Client ID
                    </h1>
                    <TextInput
                        name="userid"
                        disabled={mode === 'edit'}
                        value={brokerDetails.userid}
                        fullWidth
                        size="small"
                        placeholder="Enter your Client ID"
                        onChange={handleChange}
                    />
                </div>
                {mode !== 'edit' &&
                    <div>
                        <h1 className="mb-2.5 font-semibold">
                            Generate Redirect & Postback URLs
                        </h1>
                        <ColoredButton
                            className="hover:scale-105"
                            variant="contained"
                            size="small"
                            onClick={generateLinks}
                        >
                            Generate Links
                        </ColoredButton>
                        <div className="mt-2.5">
                            <h1>Redirect URL: <span className="text-blue-500 underline">{redirectUrl}</span>&nbsp;
                                <IconButton onClick={copyRedirectUrl}><ContentCopy fontSize="small" /></IconButton>
                            </h1>
                            <h1>Postback URL: </h1>
                        </div>
                    </div>
                }
                <div>
                    <h1 className="mb-2.5 font-semibold">
                        Copy the API Key and API secret and paste in below and click
                        Save.
                    </h1>
                    <div className="flex flex-col w-2/6 gap-3">
                        <TextInput
                            name="api_key"
                            value={brokerDetails.api_key}
                            fullWidth
                            size="small"
                            placeholder="API Key"
                            onChange={handleChange}
                        />
                        <TextInput
                            name="api_secret"
                            value={brokerDetails.api_secret}
                            fullWidth
                            size="small"
                            placeholder="API Secret"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <ColoredButton
                    className="hover:scale-105 w-fit"
                    variant="contained"
                    size="small"
                    onClick={onSaveBtnClick}
                >
                    Save
                </ColoredButton>

                <div className="flex flex-col gap-3">
                    <h1 className="text-xl font-bold">Instructions:</h1>
                    <h1>
                        1. Setup Zerodha TOTP if you haven’t already. Find instructions to
                        setup TOTP here:{" "}
                        <Link
                            to="https://support.zerodha.com/category/your-zerodha-account/login-credentials/login-credentials-of-trading-platforms/articles/time-based-otp-setup"
                            target="_blank"
                        >
                            {" "}
                            <span className="text-primary font-medium underline">
                                Zerodha TOTP setup
                            </span>
                        </Link>
                    </h1>
                    <h1>
                        2. Sign up and login to{" "}
                        <Link to="https://developers.kite.trade/signup" target="_blank">
                            {" "}
                            <span className="text-primary font-medium underline">
                                developers.kite.trade
                            </span>
                        </Link>{" "}
                        with your Zerodha credentials.
                    </h1>
                    <h1>3. Click ‘Create new app’ to create a new API subscription.</h1>
                    <h1>4. Select app type ‘Connect’.</h1>
                    <h1>5. Fill in a random name and enter your Zerodha client ID.</h1>
                    <img
                        src={zerodhaBrokerSetup}
                        alt="Zerodha broker setup"
                        height="400px"
                        width="400px"
                    />
                    <h1>
                        6. Copy the Redirect URL and Postback URL generated in Step 3, into
                        the ‘Create a new app’ section.
                    </h1>
                    <h1>
                        7. Once all of the above details are added to the form, click
                        ‘CREATE’.
                    </h1>
                    <h1>
                        8. After the succesful creation of the app, go to ‘My Apps’ and
                        click on the ‘Random name’ app that you have created.
                    </h1>
                    <h1>
                        9. Go to ‘My Apps’ and click on the ‘Random name’ app that you have
                        created and you will see a screen with the API key and API secret.
                    </h1>
                    <img
                        src={zerodhaBrokerSetup2}
                        alt="Zerodha broker setup 2"
                        height="400px"
                        width="400px"
                    />
                    <h1>
                        10. Copy the API Key and API secret and paste in the text boxes in
                        Step 4 and click ‘Save’. You are done with the setup.
                    </h1>
                </div>
            </div>
            
            {loading && <LoadingBackdrop loading={loading} />}
            
            {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />}
        </div>
    );
}

export default AddZerodhaBroker;
