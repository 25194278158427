import {
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React from "react";
import OutlinedButton from "../common/OutlinedButton";
import StyledTableCell from "../common/StyledTableCell";

function TradeLegsDetails({ open, setOpen, tradeLegs }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontFamily: "Poppins", color: "#3E4784" }}>
        {tradeLegs.name} - Trade Legs
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper} className="mt-5">
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Position</StyledTableCell>
                <StyledTableCell align="center">Lots</StyledTableCell>
                <StyledTableCell align="center">Strike Price</StyledTableCell>
                <StyledTableCell align="center">Expiry</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {tradeLegs.legs.map((tradeLeg) => {
                return (
                  <TableRow key={tradeLeg.id}>
                    <StyledTableCell align="center">
                      <Chip
                        variant="outlined"
                        color={
                          tradeLeg.order_type === "B" ? "success" : "error"
                        }
                        size="small"
                        label={tradeLeg.order_type === "B" ? "Buy" : "Sell"}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {tradeLeg.lots}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {tradeLeg.strike_price}{" "}
                      {tradeLeg.option_type === "C" ? "CE" : "PE"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {tradeLeg.expiry_date}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <OutlinedButton onClick={handleClose} size="small">
          Close
        </OutlinedButton>
      </DialogActions>
    </Dialog>
  );
}

export default TradeLegsDetails;
