import styled from "@emotion/styled";
import { TableCell, tableCellClasses } from "@mui/material";


export default styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3E4784",
    color: "white",
    fontFamily: "Poppins",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "black",
    fontFamily: "Poppins",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
}));