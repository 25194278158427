import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ColoredButton from '../components/common/ColoredButton';
import { createAxiosInstance } from './axiosConfig';

const ActiveSession = () => {
    
    const [inactive, setInactive] = useState(false);
    const navigate = useNavigate();

    const checkForInactivity = async () => {
        const expiryTime = localStorage.getItem("expiryTime");

        if(expiryTime < Date.now()) {
            setInactive(true);
            try {
                const axiosInstance = createAxiosInstance(true);
                const response = await axiosInstance.post('auth/logout/', {refresh: JSON.parse(localStorage.getItem("userAuthToken"))?.userToken?.refreshToken});

                if(response.status === 200)
                {
                    localStorage.clear();
                    window.removeEventListener("click", updateExpiryTime);
                    window.removeEventListener("keypress", updateExpiryTime);
                    window.removeEventListener("scroll", updateExpiryTime);
                    window.removeEventListener("mousemove", updateExpiryTime);
                }

            } catch(err) {
                // implement new error mechanism here
                console.log(err.message);
            }
        }
    }

    const updateExpiryTime = () => {
        const expiryTime = Date.now() + 3600000;

        localStorage.setItem("expiryTime", expiryTime);
    }

    useEffect(() => {

        const interval = setInterval(() => {
            checkForInactivity();
        }, 3600000);

        return (() => clearInterval(interval));

    }, []);

    useEffect(() => {

        updateExpiryTime();

        window.addEventListener("click", updateExpiryTime);
        window.addEventListener("keypress", updateExpiryTime);
        window.addEventListener("scroll", updateExpiryTime);
        window.addEventListener("mousemove", updateExpiryTime);

        return (() => {
            window.removeEventListener("click", updateExpiryTime);
            window.removeEventListener("keypress", updateExpiryTime);
            window.removeEventListener("scroll", updateExpiryTime);
            window.removeEventListener("mousemove", updateExpiryTime);
        })

    }, []);

    return (
        inactive ? 
        <Dialog open={inactive}>
            <DialogTitle sx={{fontFamily: "Poppins"}}>Session Expired!</DialogTitle>
            <DialogContent dividers className="flex flex-col gap-5 items-center">
                <DialogContentText sx={{fontFamily: "Poppins", color: "black"}}>Your session has been expired due to inactivity. Please login again!</DialogContentText>
                <ColoredButton size="small" variant="contained" onClick={() => navigate('/login')}>Log In</ColoredButton>
            </DialogContent>
        </Dialog> 
        : <Outlet />
    );

}

export default ActiveSession;