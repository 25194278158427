import { remove } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ColoredButton from "../components/common/ColoredButton";
import FeedbackSnackbar from "../components/common/FeedbackSnackbar";
import LoadingBackdrop from "../components/common/LoadingBackdrop";
import OutlinedButton from "../components/common/OutlinedButton";
import StrategyTypeConfig from "../components/create-strategy/StrategyTypeConfig";
import StrategyProfitabilityConfig from "../components/create-strategy/StrategyProfitabilityConfig";
import BasicStrategyDetails from "../components/create-strategy/BasicStrategyDetails";
import { createAxiosInstance } from "../Utils/axiosConfig";



function AddStrategy() {

    const location = useLocation();
    const [strategyDetails, setStrategyDetails] = useState(location?.state?.strategyDetails ? location?.state?.strategyDetails : {
        name: "",
        instrument: null,
        expiry: null,
        strategySelected: "",
        positions: [],
        lots: 1,
        execution: "buy",
        delivery: "nrml",
        targetDelta: "",
        stopLossDelta: "",
        isop: 0,
        target: 0,
        stopLoss: 0,
        is_dynamic: false,
        custom_expiry: null,
    });

    const [mode] = useState(location?.state?.mode);

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const [instrumentsList, setInstrumentsList] = useState([]);

    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });

    const [loading, setLoading] = useState(false);

    const [strikePrices, setStrikePrices] = useState({
        strikePrices: [],
        nearestStrikePrice: 0,
    });

    const [expiryDates, setExpiryDates] = useState([]);

    const navigate = useNavigate();

    const handleCreate = async () => {
        if(strategyDetails.name === "" || strategyDetails.instrument === null || strategyDetails.strategySelected === "" || strategyDetails.positions.length === 0) {
            setSnackbarMsg("error", "Please fill all the required fields");
            return;
        }
        else if((strategyDetails.isop > 0 && strategyDetails.stopLossDelta > strategyDetails.isop)) {
            setSnackbarMsg("error", "Stop loss delta cannot be greater than the ISOP in a debit strategy");
            return;
        }
        else if (strategyDetails.isop < 0 && strategyDetails.targetDelta > Math.abs(strategyDetails.isop)) {
            setSnackbarMsg("error", "Target delta cannot be greater than the ISOP in a credit strategy");
            return;
        }
        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            const requestObj = {
                name: strategyDetails.name,
                strategy:
                    strategyDetails.strategySelected === "Butter Fly"
                        ? "FLY"
                        : strategyDetails.strategySelected.toUpperCase(),
                isop: strategyDetails.isop,
                target: strategyDetails.targetDelta,
                sl: strategyDetails.stopLossDelta,
                instrument: strategyDetails.instrument,
                is_dynamic: strategyDetails.is_dynamic,
                user: JSON.parse(localStorage.getItem("loggedInUser")).pk,
            }
            const response = mode === 'edit' ? await axiosInstance.put('trades/strategy/', {
                ...requestObj,
                id: strategyDetails.id,
            }) : await axiosInstance.post('trades/strategy/', requestObj);

            if (response.status === 201 || response.status === 200) {
                const strategyId = response.data.id;
                let tradeRequestObj = [];
                for (let i = 0; i<strategyDetails.positions.length; i++) {
                    tradeRequestObj = [...tradeRequestObj, {
                        order_type: strategyDetails.positions[i].position === "buy" ? "B" : "S",
                        option_type: strategyDetails.positions[i].optionType === 3 ? "C" : "P",
                        ...(strategyDetails.is_dynamic ? ({
                            custom_expiry: strategyDetails.custom_expiry,
                            custom_strike: strategyDetails.positions[i].strikePrice 
                        }) : ({
                            expiry_date: moment(strategyDetails.expiry, "DD MMM YYYY").format("YYYY-MM-DD"),
                            strike_price: strategyDetails.positions[i].strikePrice,
                        })),
                        lots: strategyDetails.positions[i].lots ?? strategyDetails.lots,
                        strategy: strategyId,
                    }]
                }
                mode === 'edit' ? await axiosInstance.put('trades/', tradeRequestObj) : await axiosInstance.post(`trades/?strategy_id=${strategyId}`, tradeRequestObj);

                setSnackbarMsg("success", `Strategy ${mode === 'edit' ? 'saved' : 'created'} successfully`);
                setTimeout(() => {
                    navigate('/strategies');
                }, 1500);
            }
        } catch (err) {
            let msg = '';
            if (err.request.status === 404)
                msg = err.message;
            else if (err.request.status === 401 || err.request.status === 400) {
                Object.entries(err.response.data).forEach(([key, value]) => {
                    msg += key + ': ' + value + "\n";
                });
            }

            setSnackbarMsg("error", msg);
        } finally {
            setLoading(false);
        }
        return;
    }

    const handleCancel = () => {
        navigate('/strategies')
    }

    const setSnackbarMsg = (type, msg) => {
        setSnackBarOpen(true);
        setDisplayMsg({
            type: type,
            msg: msg,
        });
    };
    
    const getNearestStrikePrice = (strikePrices, ltp) => {
        var closestStrikePrice = strikePrices[1];
        if (strikePrices.length > 0) {
            closestStrikePrice = strikePrices.reduce((a, b) => {
                return Math.abs(parseFloat(b) - ltp) < Math.abs(parseFloat(a) - ltp)
                    ? b
                    : a;
            });
        }
        return closestStrikePrice;
    };

    useEffect(() => {
        async function getData() {
            setLoading(true);
            var _strikePricesList = []
            try {
                const axiosInstance = createAxiosInstance(true);
                const response = await axiosInstance.get(`trades/master/?instru_name=${strategyDetails.instrument}`)
                if (response.data?.status === "Ok") {
                    _strikePricesList = response.data[strategyDetails.instrument].strike;
                    _strikePricesList = _strikePricesList.sort((a, b) => a - b).filter((item, index) => _strikePricesList.indexOf(item) === index);
                    _strikePricesList = remove(_strikePricesList, (strikePrice) => !isNaN(strikePrice) && strikePrice !== '');

                    const ltpResponse = await axiosInstance.get(`trades/get-instru/?instrument=${strategyDetails.instrument}`);
                    if (ltpResponse.status === 200) {
                        const ltp = ltpResponse.data.ltp;
                        var _nearestStrikePrice = getNearestStrikePrice(_strikePricesList, ltp);
                        setStrikePrices({
                            strikePrices: _strikePricesList,
                            nearestStrikePrice: _nearestStrikePrice,
                        });
                    }
                }
            } catch (err) {
                console.log(err.message);
            } finally {
                setLoading(false);
            }
        }

        if(strategyDetails.instrument !== null) {
            if(!strategyDetails.is_dynamic) {
                getData();
            }
            else {
                setStrikePrices({
                    strikePrices: [...(new Array(20).fill(0).map((val, index) => "ATM-" + (index + 1)).reverse()), "ATM", ...(new Array(20).fill(0).map((val, index) => "ATM+" + (index + 1)))], 
                    nearestStrikePrice: "ATM"
                })
            }
        }

    }, [strategyDetails.instrument, strategyDetails.is_dynamic]);

    useEffect(() => {
        async function getInstruments() {
            setLoading(true);
            try {
                const axiosInstance = createAxiosInstance(true);
                const response = await axiosInstance.get("trades/master/?instru_list=true&instru_name=null");
                if (response.status === 200) {
                    var instrumentsData = [] 
                    const optionInstruments = ["NIFTY", "BANKNIFTY", "MIDCPNIFTY", "FINNIFTY"]
                    response.data.instruments.forEach(instrument => {
                        if(optionInstruments.includes(instrument))
                            instrumentsData.push({type: "Index", instrument})
                        else
                            instrumentsData.push({type: "Stock", instrument})
                    })
                    setInstrumentsList(instrumentsData);
                }
            } catch (err) {
                setSnackbarMsg("error", err.message);
            } finally {
                setLoading(false);
            }
        }

        getInstruments();
    }, []);

    useEffect(() => {
        if(mode === 'edit') {
            let positions = [];
            strategyDetails.trades.forEach(trade => {
                positions.push({
                    strikePrice: trade.strike_price,
                    optionType: trade.option_type === "C" ? 3 : 4,
                    position: trade.order_type === "B" ? "buy" : "sell",
                })
            })
            setStrategyDetails(prevData => ({
                ...prevData,
                expiry: moment(prevData.trades[0].expiry_date).format('DD MMM YYYY'),
                execution: prevData.trades[0].order_type === "B" ? "buy" : "sell",
                stopLossDelta: prevData.sl,
                targetDelta: prevData.target,
                delivery: "nrml",
                strategySelected: strategyDetails.strategy,
                positions
            }))
        }
    }, [])

    return (
        <div className="flex flex-col h-full">
            <h1 className="text-4xl font-medium text-gray-900 tracking-tight">{mode === 'edit' ? 'Edit' : 'Create a'} strategy</h1>

            <div className="flex flex-auto flex-col pt-4 w-full items-start overflow-y-auto">
                <div className="my-3 flex flex-col flex-auto overflow-y-auto w-full gap-6">
                    <BasicStrategyDetails
                        strategyDetails={strategyDetails}
                        setStrategyDetails={setStrategyDetails}
                        optionInstrumentsList={instrumentsList}
                        setExpiryDates={setExpiryDates}
                        mode={mode}
                    />

                    <div className="flex gap-20">
                        <StrategyTypeConfig
                            strategyDetails={strategyDetails}
                            setStrategyDetails={setStrategyDetails}
                            optionInstrumentsList={instrumentsList}
                            mode={mode}
                            strikePrices={strikePrices}
                            expiryDates={expiryDates}
                        />
                        <StrategyProfitabilityConfig
                            strategyDetails={strategyDetails}
                            setStrategyDetails={setStrategyDetails}
                        />
                    </div>
                </div>
                <div className="flex gap-7 mt-auto">
                    <OutlinedButton
                        variant="outlined"
                        size="small"
                        onClick={handleCancel}
                    >
                        Cancel
                    </OutlinedButton>
                    <ColoredButton
                        variant="contained"
                        size="small"
                        onClick={handleCreate}
                    >
                        {mode === 'edit' ? 'Save' : 'Create'}
                    </ColoredButton>
                </div>

                <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />

                {loading && <LoadingBackdrop loading={loading} />}

            </div>
        </div>
    );
}

export default AddStrategy;
