import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React from "react";
import OutlinedButton from "../common/OutlinedButton";
import StyledTableCell from "../common/StyledTableCell";

function EtfFundDetails({ open, setOpen, etfStrategy }) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ fontFamily: "Poppins", color: "#3E4784" }}>
                {etfStrategy.name} - Funds
            </DialogTitle>
            <DialogContent dividers>
                {Object.keys(etfStrategy.basket).length > 0 ?
                    <TableContainer component={Paper} className="mt-5">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">ETF Fund</StyledTableCell>
                                    <StyledTableCell align="center">Lots</StyledTableCell>
                                    <StyledTableCell align="center">Average Price</StyledTableCell>
                                    <StyledTableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(etfStrategy.basket).map((fund, idx) => {
                                    return (
                                        <TableRow key={idx}>
                                            <StyledTableCell align="center">
                                                {fund}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {etfStrategy.basket[fund].lots}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {etfStrategy.basket[fund].avg_price}
                                            </StyledTableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <span className="mt-5 font-bold text-sm">Funds Data not available</span>
                }
            </DialogContent>
            <DialogActions>
                <OutlinedButton onClick={handleClose} size="small">
                    Close
                </OutlinedButton>
            </DialogActions>
        </Dialog>
    );
}

export default EtfFundDetails;
