import React from 'react'
import { CirclesWithBar } from  'react-loader-spinner'

function Loading() {
  return (
    <CirclesWithBar
        height="100"
        width="100"
        color="#3E4784"
        visible={true}
        outerCircleColor=""
        innerCircleColor=""
        barColor=""
        ariaLabel='circles-with-bar-loading'
    />
  )
}

export default Loading