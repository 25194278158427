import styled from "@emotion/styled";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

const StyledDataGrid = styled(DataGrid)(() => ({
    fontFamily: 'Poppins',
    borderRadius: '8px',
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: '#F9FAFB'
    },
    '& .MuiDataGrid-columnHeader': {
        fontSize: '16px',
        color: '#667085',
        fontWeight: 500,
    },
}))

export default StyledDataGrid;