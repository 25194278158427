import { useEffect, useRef, useState } from "react";
import TextInput from "./TextInput";

let currentOtpIndex = 0;

const Otp = ({ otp, setOtp }) => {

    const [activeOtpIndex, setActiveOtpIndex] = useState(0);
    const inputRef = useRef(null);

    const onOtpInputChange = (event) => {
        const { value } = event.target;
        if (value === "1" || value === "2" || value === "3" || value === "4" || value === "5" || value === "6" || value === "7" || value === "8" || value === "9" || value === "0" || value === "") {
            const newOtp = [...otp];
            newOtp[currentOtpIndex] = value;

            if (!value) setActiveOtpIndex(currentOtpIndex - 1);
            else setActiveOtpIndex(currentOtpIndex + 1);
            setOtp(newOtp);
        }
    };

    const handleOnKeyDown = ({ key }, index) => {
        currentOtpIndex = index;
        if (key === "Backspace") {
            setActiveOtpIndex(currentOtpIndex - 1);
        } else if (key === "1" || key === "2" || key === "3" || key === "4" || key === "5" || key === "6" || key === "7" || key === "8" || key === "9" || key === "0") {
            setActiveOtpIndex(currentOtpIndex + 1);
        }
    };

    useEffect(() => {
        inputRef.current?.focus();
    }, [activeOtpIndex]);

    return (
        <div className="flex gap-3">
            {otp.map((item, index) => {
                return (
                    <TextInput
                        size="small" variant="outlined" autoComplete="off" placeholder="X"
                        value={otp[index]} inputRef={index === activeOtpIndex ? inputRef : null}
                        key={index} inputProps={{ maxLength: 1 }}
                        sx={{
                            "& .MuiInputBase-input": { textAlign: "center" },
                        }}
                        onChange={onOtpInputChange}
                        onKeyDown={(event) => handleOnKeyDown(event, index)}
                    />
                )
            })}
        </div>
    )
}

export default Otp;