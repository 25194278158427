import { createTheme } from "@mui/material";

const colorTheme = createTheme({
    palette: {
        primary: {
            main: '#3E4784',
        },
        secondary: {
            main: '#FFFFFF',
        }
    },
    typography: {
        fontFamily: [
            'Poppins',
            'sans-serif',
        ].join(',')
    }
})

export default colorTheme;