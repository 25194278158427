import styled from "@emotion/styled";
import { Button } from "@mui/material";

const OutlinedButton = styled(Button)({
    whiteSpace: 'nowrap',
    minWidth: 'auto',
    borderWidth: 2,
    borderRadius: '8px',
    "&:hover": {
        borderWidth: 2,
    },
});

export default OutlinedButton;
