import React, { useState } from "react";
import AngeloneLogo from "../../assets/angelone_logo.png";
import ShoonyaLogo from "../../assets/shoonya_logo.png";
import ZerodhaLogo from "../../assets/zerodha_logo.png";
import _ from "lodash";


const BrokerAccount = ({ broker, brokerAccount, setBrokerAccountsSelected }) => {
    const [isSelected, setIsSelected] = useState(false);

    const handleBrokerAccountSelected = (isSelected, id) => {
        setIsSelected(isSelected);
        if(isSelected) {
            setBrokerAccountsSelected(prevData => {
                const newData = { ...prevData };
                newData[broker].push(id);
                return newData;
            })
        }
        else {
            setBrokerAccountsSelected(prevData => {
                const newData = { ...prevData };
                _.remove(newData[broker], (id) => id === brokerAccount.id);
                return newData;
            })
        }
    }

    return (
        <div className={`p-4 rounded-lg border ${isSelected && 'bg-[#F9F5FF]'} ${brokerAccount.login_token === 'None' && 'bg-[#D0D5DD]'}`}>
            <div className="flex gap-3">
                <img src={broker === 'finvasia' ? ShoonyaLogo : broker === 'angel' ? AngeloneLogo : ZerodhaLogo} width='45px' height='45px' />
                <div className="flex flex-col">
                    <h2 className="text-sm font-medium text-[#293056]">{brokerAccount.nickname}</h2>
                    <p className="text-sm font-normal text-[#3E4784]">Client ID: {brokerAccount.userid}</p>
                    {brokerAccount.login_token === 'None' && <p className="text-xs font-normal text-[#3E4784]">Broker account not logged in</p>}
                </div>
                <div className="ml-auto">
                    <input type="checkbox" checked={isSelected} disabled={brokerAccount.login_token === 'None'} onChange={(e) => handleBrokerAccountSelected(e.target.checked, brokerAccount.id)} />
                </div>
            </div>
        </div>
    )
}

export default BrokerAccount;
