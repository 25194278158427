import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingBackdrop from "../components/common/LoadingBackdrop";
import ColoredButton from "../components/common/ColoredButton";
import FeedbackSnackbar from "../components/common/FeedbackSnackbar";
import TextInput from "../components/common/TextInput";
import { createAxiosInstance } from "../Utils/axiosConfig";


function AddAngelBroker() {

    const location = useLocation();
    const [brokerDetails, setBrokerDetails] = useState(location.state?.accountDetails ? location.state.accountDetails :
        {
            nickname: "",
            userid: "",
            api_key: "",
            password: ""
        });

    const [mode] = useState(location.state?.mode === 'edit' ? 'edit' : 'create');

    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;

        setBrokerDetails((prevData) => {
            return { ...prevData, [name]: value };
        });
    };

    const onSaveBtnClick = async () => {
        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            let response;
            if (mode === 'edit') {
                response = await axiosInstance.put('trades/angel/', {
                    ...brokerDetails,
                    user: JSON.parse(localStorage.getItem("loggedInUser")).pk
                })
            }
            else {
                response = await axiosInstance.post('trades/angel/', {
                    ...brokerDetails,
                    user: JSON.parse(localStorage.getItem("loggedInUser")).pk
                });
            }

            if (response.status === 201) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Broker account setup successfully", type: "success" });
            }
            else if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Broker account updated successfully", type: "success" });
            }

            setTimeout(() => {
                navigate("/brokerAccountsSetup");
            }, 1500);

        } catch (err) {
            let msg = '';
            if (err.request.status === 400) {
                err.response.data?.detail ? msg = err.response.data.detail
                    : Object.entries(err.response.data).forEach(([key, value]) => {
                        msg += key + ': ' + value + "\n";
                    });
            }
            else if (err.request.status === 401 || err.request.status === 404)
                msg = err.message;

            setSnackBarOpen(true);
            setDisplayMsg({ msg: msg, type: "error" });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col gap-8 overflow-y-auto h-full">

            <div className="flex flex-col gap-5 overflow-y-auto">
                <h1 className="text-4xl font-medium text-gray-900 tracking-tight">Create Angel broker account</h1>
                <div>
                    <h1 className="mb-2.5 font-semibold">
                        Enter account nickname
                    </h1>
                    <TextInput
                        name="nickname"
                        value={brokerDetails.nickname}
                        sx={{ "&.MuiTextField-root": { width: "33%" } }}
                        size="small"
                        placeholder="Enter account nickname"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <h1 className="mb-2.5 font-semibold">
                        Enter your client ID
                    </h1>
                    <TextInput
                        name="userid"
                        disabled={mode === 'edit'}
                        value={brokerDetails.userid}
                        sx={{ "&.MuiTextField-root": { width: "33%" } }}
                        size="small"
                        placeholder="Enter your client ID"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <h1 className="mb-2.5 font-semibold">
                        Enter your API key
                    </h1>
                    <div className="flex flex-col w-2/6 gap-3">
                        <TextInput
                            name="api_key"
                            value={brokerDetails.api_key}
                            fullWidth
                            size="small"
                            placeholder="API Key"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div>
                    <h1 className="mb-2.5 font-semibold">
                        Enter your password
                    </h1>
                    <TextInput
                        name="password"
                        value={brokerDetails.password}
                        sx={{ "&.MuiTextField-root": { width: "33%" } }}
                        size="small"
                        placeholder="Enter your password"
                        onChange={handleChange}
                    />
                </div>
                <ColoredButton
                    className="hover:scale-105 w-fit"
                    variant="contained"
                    size="small"
                    onClick={onSaveBtnClick}
                >
                    Save
                </ColoredButton>
            </div>
            
            {loading && <LoadingBackdrop loading={loading} />}
            
            {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />}
        </div>
    );
}

export default AddAngelBroker;
