import { HelpOutline } from "@mui/icons-material";
import {
    IconButton,
    Tooltip,
    Zoom
} from "@mui/material";
import React, { useEffect } from "react";
import TextInput from "../common/TextInput";

function StrategyProfitabilityConfig({ strategyDetails, setStrategyDetails }) {

    const handleChange = (event) => {
        const { name, value } = event.target;

        if(name === "targetDelta") {
            var _target = strategyDetails.is_dynamic ? value : parseFloat(strategyDetails.isop) + parseFloat(value === "" ? 0 : value);
            
            setStrategyDetails(prevData => ({
                ...prevData,
                [name]: value,
                target: Math.round(_target * 100) / 100,
            }))
        }

        else if (name === "stopLossDelta") {
            var _stopLoss = strategyDetails.is_dynamic ? value : parseFloat(strategyDetails.isop) - parseFloat(value === "" ? 0 : value);

            setStrategyDetails(prevData => ({
                ...prevData,
                [name]: value,
                stopLoss: Math.round(_stopLoss * 100) / 100,
            }))
        }
    };

    useEffect(() => {
        if(!strategyDetails.is_dynamic) {
            var _isop = 0;
            strategyDetails.positions?.forEach((position) => {
                if (position.position === "buy") _isop += parseFloat(position.entryPrice);
                else _isop -= parseFloat(position.entryPrice);
            });

            setStrategyDetails((prevData) => {
                return { ...prevData, isop: Math.round(_isop * 100) / 100 };
            });
        }
    }, [strategyDetails.positions]);

    return (
        <div className="flex flex-col gap-3">
            <h1 className="text-sm font-medium">Profitability</h1>
            <div className="flex flex-col gap-8">
                <div className="flex gap-4">
                    <div className="flex flex-col gap-2">
                        <label className="font-medium text-sm">
                            Target Delta<span className="text-red-700">*</span>
                        </label>
                        <TextInput
                            name="targetDelta"
                            value={strategyDetails.targetDelta}
                            type="number"
                            size="small"
                            placeholder="Enter Target Delta"
                            onChange={handleChange}
                            autoComplete="off"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium text-sm">
                            Stoploss Delta<span className="text-red-700">*</span>
                        </label>
                        <TextInput
                            name="stopLossDelta"
                            type="number"
                            value={strategyDetails.stopLossDelta}
                            size="small"
                            placeholder="Enter Stoploss Delta"
                            onChange={handleChange}
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="flex gap-20">
                    {!strategyDetails.is_dynamic &&
                        <div className="flex flex-col gap-3">
                            <h1 className="text-sm font-medium">
                                Current Price{" "}
                                <Tooltip
                                    disableFocusListener
                                    TransitionComponent={Zoom}
                                    title="Net sum of premium of all strike prices (Debit - Credit)"
                                >
                                    <IconButton sx={{ padding: 0 }}>
                                        <HelpOutline fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </h1>
                            <h1 className="text-sm">
                                {strategyDetails.isop >= 0 ? "(Debit) " : "(Credit) "}
                                {strategyDetails.isop}
                            </h1>
                        </div>
                    }
                    <div className="flex flex-col gap-3">
                        <h1 className="font-medium text-sm">Target</h1>
                        <h1 className="text-sm">
                            {strategyDetails.target >= 0 ? "(Debit) " : "(Credit) "}
                            {strategyDetails.target}
                        </h1>
                    </div>
                    <div className="flex flex-col gap-3">
                        <h1 className="font-medium text-sm">Stop Loss</h1>
                        <h1 className="text-sm">
                            {strategyDetails.stopLoss >= 0 ? "(Debit) " : "(Credit) "}
                            {strategyDetails.stopLoss}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StrategyProfitabilityConfig;
