import { SvgIcon } from "@mui/material";
import React from "react";

function NavItem({ iconSvg, collapsed, menuItem, active }) {
  return (
    <div className={`${active && 'rounded-md active-nav-bg'} p-2 flex gap-6 items-center`}>
        <SvgIcon>
            {iconSvg}
        </SvgIcon>
        {collapsed && <h1 className="text-sm font-medium text-white">{menuItem}</h1>}
    </div>
  );
}

export default NavItem;
