import { Search } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import React from 'react'
import TextInput from './TextInput'

const SearchInput = () => {
    return (
        <TextInput fullWidth variant="outlined" size="small" InputProps={{
            startAdornment: <InputAdornment position="start"><Search sx={{
                color: '#667085'
            }} /></InputAdornment>,
        }} />
    )
}

export default SearchInput