import { GitHub, LinkedIn, WhatsApp } from '@mui/icons-material';
import React from 'react';
import girish from "../assets/girish.png";
import kavish from "../assets/kavish.png";

const About = () => {
  return (
        <section className='flex justify-center items-center pt-10 pb-28'>
            <div className='grid grid-cols-4 gap-14'>
                <div className='flex flex-col gap-[18px] bg-white p-5 rounded-[20px]'>
                    <div className='w-[250px] h-[209px] rounded-[10px] bg-[#EBEBEB]'>
                    </div>

                    <div className='flex flex-col gap-[10px] items-center w-full'>
                            <span className='text-[#191A15] font-semibold text-lg'>Manoj</span>
                            <span className='text-[#A6A6A6] font-medium text-sm'>Management wizard</span>
                    </div>

                    <div className='flex py-2 px-4 gap-4 bg-[#EBEBEB] justify-center'>
                        <LinkedIn fontSize='medium' />
                        <WhatsApp fontSize='medium' />
                        <GitHub fontSize='medium' />
                    </div>
                </div>

                    <div className='flex flex-col gap-[18px] bg-white p-5 rounded-[20px]'>
                        <div className='w-[250px] h-[209px] rounded-[10px] bg-[#EBEBEB]'>
                        <img src={kavish} alt='kavish' />
                        </div>

                        <div className='flex flex-col gap-[10px] items-center w-full'>
                            <span className='text-[#191A15] font-semibold text-lg'>Kavish Kumar S</span>
                            <span className='text-[#A6A6A6] font-medium text-sm'>UI Alchemist</span>
                        </div>

                        <div className='flex py-2 px-4 gap-4 bg-[#EBEBEB] justify-center'>
                            <LinkedIn fontSize='medium' />
                            <WhatsApp fontSize='medium' />
                            <GitHub fontSize='medium' />
                        </div>
                    </div>

                    <div className='flex flex-col gap-[18px] bg-white p-5 rounded-[20px]'>
                        <div className='w-[250px] h-[209px] rounded-[10px] bg-[#EBEBEB]'>
                        </div>

                        <div className='flex flex-col gap-[10px] items-center w-full'>
                            <span className='text-[#191A15] font-semibold text-lg'>Jogendra Singh</span>
                            <span className='text-[#A6A6A6] font-medium text-sm'>Backend Magician</span>
                        </div>

                        <div className='flex py-2 px-4 gap-4 bg-[#EBEBEB] justify-center'>
                            <LinkedIn fontSize='medium' />
                            <WhatsApp fontSize='medium' />
                            <GitHub fontSize='medium' />
                        </div>
                    </div>

                    <div className='flex flex-col gap-[18px] bg-white p-5 rounded-[20px]'>
                        <div className='w-[250px] h-[209px] rounded-[10px] bg-[#EBEBEB]'>
                        <img src={girish} alt='girish' />
                        </div>

                        <div className='flex flex-col gap-[10px] items-center w-full'>
                            <span className='text-[#191A15] font-semibold text-lg'>Girish Somani</span>
                            <span className='text-[#A6A6A6] font-medium text-sm'>UI/UX Sorcerer</span>
                        </div>

                        <div className='flex py-2 px-4 gap-4 bg-[#EBEBEB] justify-center'>
                            <LinkedIn fontSize='medium' />
                            <WhatsApp fontSize='medium' />
                            <GitHub fontSize='medium' />
                        </div>
                    </div>
            </div>
        </section>
  )
}

export default About