import { ArrowDownward, ArrowUpward, Edit, ExitToApp, HighlightOff } from "@mui/icons-material";
import {
    Link,
    Tooltip
} from "@mui/material";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import { capitalize } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { BsCheck2Circle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { createAxiosInstance } from "../../Utils/axiosConfig";
import ColoredButton from "../common/ColoredButton";
import DialogBoxWrapper from "../common/DialogBoxWrapper";
import FeedbackSnackbar from "../common/FeedbackSnackbar";
import LoadingBackdrop from "../common/LoadingBackdrop";
import NoRowsOverlay from "../common/NoRowsOverlay";
import StyledDataGrid from "../common/StyledDataGrid";
import OpenStrategyEditDialog from "./OpenStrategyEditDialog";
import TradeLegsDetails from "./TradeLegsDetails";
import { useWebSocketContext } from "../../Utils/WebSocketProvider";


function renderPL(props) {
    const { value } = props;

    return (
        <h1
            className={`font-semibold ${value > 0 ? "text-green-500" : "text-red-500"
                }`}
        >
            {value}
        </h1>
    );
}

// https://socket.io/how-to/use-with-react

export default function OpenStrategiesTable({
    openStrategies,
}) {

    const { ws } = useWebSocketContext();
    
    const columns = [
        {
            field: "name",
            headerName: "Strategy Name",
            headerAlign: "left",
            renderCell: renderStrategyName,
            align: "left",
            width: 200,
        },
        {
            field: "instrument",
            headerName: "Instrument",
            width: 150,
        },
        {
            field: "broker_ids",
            headerName: "Broker",
            width: 180,
            valueFormatter: (params) => {
                let brokers = [];
                Object.keys(params.value).forEach(broker => {
                    if (params.value[broker].failed?.length > 0 || params.value[broker].passed?.length > 0)
                        brokers.push(capitalize(broker));
                })

                return brokers.join(", ");
            }
        },
        {
            field: "lots",
            headerName: "Lots",
            headerAlign: "left",
            width: 100,
        },
        {
            field: "isop",
            type: "number",
            headerName: "ISOP",
            width: 100,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "csop",
            type: "number",
            headerName: "CSOP",
            width: 100,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "target",
            headerName: "Target",
            editable: true,
            width: 120,
            valueGetter: (params) => {
                const target = parseFloat(params.row.isop) + parseFloat(params.value);
                return Math.round(target * 100) / 100;
            },
            align: "left",
            headerAlign: "left",
        },
        {
            field: "sl",
            headerName: "Stop Loss",
            editable: true,
            width: 120,
            valueGetter: (params) => {
                const stopLoss = parseFloat(params.row.isop) - parseFloat(params.value);
                return Math.round(stopLoss * 100) / 100;
            },
            align: "left",
            headerAlign: "left",
        },
        {
            field: "pl",
            headerName: "Current P/L",
            width: 135,
            renderCell: renderPL,
            align: "center",
            headerAlign: "left",
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 100,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<Tooltip title='Edit strategy'><Edit sx={{ color: "black" }} /></Tooltip>}
                        label="Edit"
                        onClick={() => handleEditClick(params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title='Exit strategy'><ExitToApp sx={{ color: "black" }} /></Tooltip>}
                        label="Exit"
                        onClick={() => handleExitClick(params.id)()}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title='Abort strategy'><HighlightOff sx={{ color: "black" }} /></Tooltip>}
                        label="Abort"
                        onClick={() => handleAbortClick(params.id)}
                    />
                ];
            },
        },
    ];

    function renderStrategyName(props) {
        const { value, id } = props;

        return (
            <div>
                <Link
                    sx={{
                        color: "black",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        textDecoration: "none",
                        borderBottom: "1px solid black",
                    }}
                    component="button"
                    onClick={() => showStrategyLegDetails(id)}
                    variant="body2"
                >
                    {value}
                </Link>
            </div>
        );
    }

    const handleExitClick = (id) => async () => {
        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            const response = await axiosInstance.post('trades/strategy/exit/?id=' + id)

            if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Exited the strategy", type: "success" });
                setTimeout(() => {
                    navigate(0);
                }, 1000);
            }

        } catch (err) {
            setSnackBarOpen(true);
            setDisplayMsg({ msg: err.message, type: "error" });
        } finally {
            setLoading(false);
        }
    };

    const [rows, setRows] = useState(openStrategies);

    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const [loading, setLoading] = useState(false);

    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });

    const [tradeLegsOpen, setTradeLegsOpen] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);

    const [showAbortConfirmDialog, setShowAbortConfirmDialog] = useState(false);

    const [strategyToAbort, setStrategyToAbort] = useState(undefined);

    const [strategyEditDialog, setStrategyEditDialog] = useState(false);

    const [strategyToEdit, setStrategyToEdit] = useState(undefined);

    const [newProfitability, setNewProfitability] = useState({
        target: "",
        sl: ""
    })

    const apiRef = useGridApiRef();

    const navigate = useNavigate();

    const [tradeLegs, setTradeLegs] = useState({
        strategyName: "",
        legs: [],
    });

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const onExitBtnClick = () => {
        setDialogOpen(true);
    };

    const onYesBtnClick = async () => {
        setLoading(true);
        var selectedRows = apiRef.current.getSelectedRows();
        var promiseArray = [];
        const axiosInstance = createAxiosInstance(true);
        if (selectedRows.size === rows.length)
            promiseArray.push(axiosInstance.post('trades/exitall/'))
        else {
            for (var row of selectedRows)
                promiseArray.push(axiosInstance.post('trades/strategy/exit/?id=' + row[1].id));
        }

        Promise.all(promiseArray).then(() => {
            setSnackBarOpen(true);
            setDisplayMsg({ msg: "Exited the selected strategies", type: "success" });
            setTimeout(() => {
                navigate(0);
            }, 1000);
        }).catch((err) => {
            setSnackBarOpen(true);
            setDisplayMsg({ msg: err.message, type: "error" });
        })
        setLoading(false);
        setDialogOpen(false);
    };

    const showStrategyLegDetails = async (id) => {
        var strategy = openStrategies.find((strategy) => strategy.id === id);
        setTradeLegs({ name: strategy.name, legs: strategy.trades ?? [] });
        setTradeLegsOpen(true);
    };

    const handleAbortClick = (id) => {
        setShowAbortConfirmDialog(true);
        setStrategyToAbort(id);
    }

    const handleEditClick = (row) => {
        setStrategyEditDialog(true);
        setStrategyToEdit(row);
    }

    const abortStrategy = async () => {
        if (strategyToAbort) {
            setLoading(true);
            try {
                const axiosInstance = createAxiosInstance(true);
                const response = await axiosInstance.post(`trades/strategy/abort/?id=${strategyToAbort}`)
                if (response.status === 200) {
                    setSnackBarOpen(true);
                    setDisplayMsg({ msg: "Strategy aborted successfully", type: "success" });
                    navigate(0);
                    setStrategyToAbort(undefined);
                }
            } catch (err) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: err, type: "error" });
            } finally {
                setLoading(false);
                setShowAbortConfirmDialog(false);
            }
        }
    }

    const handleYes = async () => {
        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            const response = await axiosInstance.put('trades/strategy/', { ...strategyToEdit, target: newProfitability.target, sl: newProfitability.sl });
            if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Strategy updated successfully", type: "success" });
                navigate(0);
            }
        } catch (err) {
            setSnackBarOpen(true);
            setDisplayMsg({ msg: err.message, type: "error" });
        } finally {
            setLoading(false);
            setStrategyEditDialog(false);
        }
    };

    const receiveSocketData = () => {
        ws.onmessage = ({ data }) => {
            const notification = JSON.parse(data);
            if (notification.type === "send_notif") {
                setSnackBarOpen(true);
                setDisplayMsg({
                    type: "success",
                    msg: notification.message.head + ':' + notification.message.body,
                });
                const updatedRows = rows.filter(
                    (row) => row.id !== parseInt(notification.id)
                );
                setRows(updatedRows);
            } else if (notification.status === "placed" || notification.status === "stopped" || notification.status === "pending") {
                setRows((prevData) => {
                    const newData = [...prevData];
                    const objectIdx = newData.findIndex(
                        (row) => row.id === parseInt(notification.id)
                    );
                    newData[objectIdx].csop = notification.csop;
                    newData[objectIdx].pl = notification.pl;
                    newData[objectIdx].isop = notification.isop;
                    newData[objectIdx].status = notification.status;
                    return newData;
                });
            }
        };
    };

    useEffect(() => {
        if (ws) {
            receiveSocketData();
        }
    }, []);

    return (
        <div className="h-full w-full flex flex-col gap-5">
            <StyledDataGrid
                rows={rows}
                columns={columns}
                slots={{
                    noRowsOverlay: () => (
                        <NoRowsOverlay msg="You don't have any open strategy yet" />
                    ),
                    columnSortedAscendingIcon: () => (
                        <ArrowDownward fontSize="small" sx={{ color: "black" }} />
                    ),
                    columnSortedDescendingIcon: () => (
                        <ArrowUpward fontSize="small" sx={{ color: "black" }} />
                    ),
                }}
                disableRowSelectionOnClick
                checkboxSelection
                disableColumnMenu
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                apiRef={apiRef}
                editMode="cell"
            />

            <div>
                <ColoredButton
                    disabled={!rowSelectionModel.length > 0}
                    variant="contained"
                    size="small"
                    onClick={onExitBtnClick}
                >
                    Exit Strateg
                    {rowSelectionModel.length > 1 ? "ies" : "y"}
                </ColoredButton>
            </div>

            <TradeLegsDetails
                open={tradeLegsOpen}
                setOpen={setTradeLegsOpen}
                tradeLegs={tradeLegs}
            />

            {dialogOpen && <DialogBoxWrapper title='Confirmation' contentComponent='Are you sure you want to exit the selected strategies?' open={dialogOpen} setOpen={setDialogOpen} confirmHandler={onYesBtnClick} />}

            {showAbortConfirmDialog && <DialogBoxWrapper
                iconComp={<IconContext.Provider value={{ color: '#3E4784', size: '1.2em' }}><BsCheck2Circle /></IconContext.Provider>}
                title={'Are you sure?'}
                contentComponent={'Are you sure you want to abort this strategy?'}
                open={showAbortConfirmDialog}
                setOpen={setShowAbortConfirmDialog}
                confirmHandler={abortStrategy}
            />
            }

            {strategyEditDialog && <DialogBoxWrapper
                iconComp={<Edit sx={{ fontSize: '1.2em', color: '#3E4784' }} />}
                title="Edit target and stop loss"
                contentComponent={<OpenStrategyEditDialog strategy={strategyToEdit} profitability={newProfitability} setProfitability={setNewProfitability} />}
                open={strategyEditDialog}
                setOpen={setStrategyEditDialog}
                confirmHandler={handleYes}
            />}

            {loading && <LoadingBackdrop loading={loading} />}

            {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />}
        </div>
    );
}
