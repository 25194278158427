import { FileDownload, FilterList } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import ColoredButton from '../components/common/ColoredButton'
import NavTab from '../components/common/NavTab'
import OutlinedButton from '../components/common/OutlinedButton'
import SearchInput from '../components/common/SearchInput'
import ReportsTable from '../components/tradebook/ReportsTable'
import { createAxiosInstance } from '../Utils/axiosConfig'
import LoadingBackdrop from '../components/common/LoadingBackdrop';
import { Menu, MenuItem } from '@mui/material'


const Reports = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [startDate, setStartDate] = useState(new moment().subtract(1, "days"));
    const [endDate, setEndDate] = useState(new moment());
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fetchData, setFetchData] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const open = Boolean(anchorEl);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    }

    const handleEndDateChange = (date) => {
        setEndDate(date);
    }

    const handleGroupByClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleGroupByStrategy = () => {
        const result = Object.groupBy(data, ({ strategy_name }) => strategy_name);
        setFilteredData(result);
        setAnchorEl(null);
    }

    const handleGroupByBroker = () => {
        const result = Object.groupBy(data, ({ brokers }) => Object.keys(brokers)[0]);
        setFilteredData(result);
        setAnchorEl(null);
    }

    useMemo(async () => {
        const axiosInstance = createAxiosInstance(true);
        setLoading(true);
        if(activeTab === 0) {
            const response = await axiosInstance.get(`trades/tradebook/?start_date=${new moment(startDate).format("YYYY-MM-DD")}&end_date=${new moment(endDate).format("YYYY-MM-DD")}`)
            setData(response.data);
            setFilteredData(response.data);
        } else if (activeTab === 1) {
            const response = await axiosInstance.get(`trades/orderbook/`)
            setData(response.data);
            setFilteredData(response.data);
        }
        setLoading(false);
    }, [fetchData, activeTab])

    return (
        <div className="h-full flex flex-col">
            <div className="flex justify-between">
                <h1 className="text-4xl font-medium text-gray-900 tracking-tight">Reports</h1>
                <ColoredButton variant="contained" size="small" startIcon={<FileDownload />}>Download</ColoredButton>
            </div>

            <div className='flex gap-2 mt-6'>
                <NavTab tabId={0} isActive={activeTab === 0} title="Trade book" setActiveTab={setActiveTab} />
                <NavTab tabId={1} isActive={activeTab === 1} title="Order book" setActiveTab={setActiveTab} />
            </div>

            <div className='flex items-center mt-6 gap-3 w-full'>
                {activeTab === 0 && 
                    <>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker label='Start Date' value={startDate} defaultValue={new moment().format('YYYY-MM-DD')}
                                onChange={handleStartDateChange} slotProps={{ textField: { size: 'small' } }}
                                disableFuture format="YYYY-MM-DD" views={['year', 'month', 'day']}
                            />
                        </LocalizationProvider>
                        <span>-</span>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker label='End Date' value={endDate} defaultValue={new moment().format('YYYY-MM-DD')}
                                onChange={handleEndDateChange} slotProps={{ textField: { size: 'small' } }}
                                disableFuture format="YYYY-MM-DD" views={['year', 'month', 'day']} minDate={startDate}
                            />
                        </LocalizationProvider>
                        <ColoredButton variant='contained' size='small' onClick={() => setFetchData(prev => !prev)}>Go</ColoredButton>
                    </>
                }
                <SearchInput />
                <OutlinedButton variant='outlined' sx={{ textTransform: 'capitalize', color: '#344054' }} startIcon={<FilterList sx={{ color: '#344054' }} />} onClick={handleGroupByClick}>Group By</OutlinedButton>
            </div>

            <div className='mt-6 flex-auto overflow-auto pr-4'>
                <ReportsTable activeTab={activeTab} tradesData={filteredData} />
            </div>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={handleGroupByStrategy}>Strategy</MenuItem>
                <MenuItem onClick={handleGroupByBroker}>Broker</MenuItem>
            </Menu>

            {loading && <LoadingBackdrop loading={loading} />}
        </div>
    )
}

export default Reports
