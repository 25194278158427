import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ColoredButton from "../components/common/ColoredButton";
import FeedbackSnackbar from "../components/common/FeedbackSnackbar";
import LoadingBackdrop from "../components/common/LoadingBackdrop";
import TextInput from "../components/common/TextInput";
import { createAxiosInstance } from "../Utils/axiosConfig";

function AddFinvasiaBroker() {

    const location = useLocation();
    const [brokerDetails, setBrokerDetails] = useState(location.state?.accountDetails ? location.state.accountDetails :
        {
            userid: "",
            password: "",
            vendor_code: "",
            api_secret: "",
            imei: "",
            nickname: ""
        });
    const [mode] = useState(location.state?.mode === 'edit' ? 'edit' : 'create');

    const [loading, setLoading] = useState(false);

    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBrokerDetails((prevData) => {
            return { ...prevData, [name]: value };
        });
    };

    const onSaveBtnClick = async () => {
        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            var response;
            if (mode === 'edit') {
                response = await axiosInstance.put('trades/shoonya/', {
                    ...brokerDetails,
                    user: JSON.parse(localStorage.getItem("loggedInUser")).pk
                })
            }
            else {
                response = await axiosInstance.post('trades/shoonya/', {
                    ...brokerDetails,
                    user: JSON.parse(localStorage.getItem("loggedInUser")).pk,
                })
            };

            if (response.status === 201) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Broker account setup successfully", type: "success" });
            }
            else if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Broker account updated successfully", type: "success" });
            }

            setTimeout(() => {
                navigate("/brokerAccountsSetup");
            }, 1500);

        } catch (err) {
            let msg = '';
            if (err.request.status === 400) {
                err.response.data?.detail ? msg = err.response.data.detail
                    : Object.entries(err.response.data).forEach(([key, value]) => {
                        msg += key + ': ' + value + "\n";
                    });
            }
            else if (err.request.status === 401 || err.request.status === 404)
                msg = err.message;

            setSnackBarOpen(true);
            setDisplayMsg({ msg: msg, type: "error" });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col overflow-y-auto h-full">
            <div className="flex-auto flex flex-col gap-3 overflow-y-auto">
                <h1 className="text-4xl font-medium text-gray-900 tracking-tight">Create Finvasia broker account</h1>
                <div className="w-2/4">
                    <h1 className="mb-2.5 font-semibold">Account nicname</h1>
                    <TextInput
                        name="nickname"
                        value={brokerDetails.nickname}
                        size="small"
                        fullWidth
                        placeholder="Enter account nickname"
                        onChange={handleChange}
                    />
                </div>
                <div className="w-2/4">
                    <h1 className="mb-2.5 font-semibold">Finvasia Client ID</h1>
                    <TextInput
                        name="userid"
                        disabled={mode === 'edit'}
                        value={brokerDetails.userid}
                        size="small"
                        fullWidth
                        placeholder="Enter Client ID"
                        onChange={handleChange}
                    />
                </div>
                <div className="w-2/4">
                    <h1 className="mb-2.5 font-semibold">Finvasia password</h1>
                    <TextInput
                        name="password"
                        value={brokerDetails.password}
                        type={"password"}
                        fullWidth
                        size="small"
                        placeholder="Enter Password"
                        onChange={handleChange}
                    />
                </div>
                <div className="w-2/4">
                    <h1 className="mb-2.5 font-semibold">Vendor Code</h1>
                    <TextInput
                        name="vendor_code"
                        value={brokerDetails.vendor_code}
                        fullWidth
                        size="small"
                        placeholder="Enter Vendor Code"
                        onChange={handleChange}
                    />
                </div>
                <div className="w-2/4">
                    <h1 className="mb-2.5 font-semibold">API Secret</h1>
                    <TextInput
                        name="api_secret"
                        value={brokerDetails.api_secret}
                        fullWidth
                        size="small"
                        placeholder="Enter API Secret"
                        onChange={handleChange}
                    />
                </div>
                <div className="w-2/4">
                    <h1 className="mb-2.5 font-semibold">IMEI</h1>
                    <TextInput
                        name="imei"
                        value={brokerDetails.imei}
                        fullWidth
                        size="small"
                        placeholder="Enter IMEI"
                        onChange={handleChange}
                    />
                </div>
                <ColoredButton
                    className="hover:scale-105 w-fit"
                    variant="contained"
                    size="small"
                    onClick={onSaveBtnClick}
                >
                    Save
                </ColoredButton>
                <div className="w-3/4">
                    <ul className="list-decimal list-inside">
                        <li>
                            Go to{" "}
                            <Link to="https://prism.finvasia.com">
                                <span className="text-primary">https://prism.finvasia.com</span>
                            </Link>{" "}
                            and login.
                        </li>
                        <li>
                            After logging in, go to{" "}
                            <Link to="https://prism.finvasia.com/api">
                                <span className="text-primary">https://prism.finvasia.com</span>
                            </Link>
                        </li>
                        <li>
                            Copy the API key and Vendor code from the API details page and
                            paste it in the broker setup settings above, and click Save.
                        </li>
                        <li>
                            Go to the Broker Login page on Tradebuddys and login. If the setup
                            was done correctly, you will be able to successfully login and
                            generate the access token.
                        </li>
                    </ul>
                </div>
            </div>
            
            {loading && <LoadingBackdrop loading={loading} />}
           
            {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />}
        </div>
    );
}

export default AddFinvasiaBroker;
