import React from 'react'

const LayoutWrapper = ({children}) => {
  return (
    <div className='h-screen rounded-t-3xl py-6 px-8'>
        {children}
    </div>
  )
}

export default LayoutWrapper