import { ThemeProvider } from '@emotion/react'
import React from 'react'
import { Outlet } from 'react-router-dom'
import colorTheme from '../Utils/theme'
import LandingPageHeader from '../components/landing-page/LandingPageHeader'
import Footer from '../components/landing-page/Footer'

const StaticPages = () => {
  return (
    <ThemeProvider theme={colorTheme}>
        <div className="bg-white h-screen">
            <div className="landing_page-bg pb-24 h-full">
                <LandingPageHeader />
                <Outlet />
                <Footer />
            </div>
        </div>  
    </ThemeProvider>
  )
}

export default StaticPages