import { Backdrop } from '@mui/material'
import React from 'react'
import Loading from './Loading'

const LoadingBackdrop = ({loading}) => {
  return (
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
          <Loading />
      </Backdrop>
  )
}

export default LoadingBackdrop