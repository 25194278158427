import {
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    createFilterOptions
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createAxiosInstance } from "../../Utils/axiosConfig";
import ColoredButton from "../common/ColoredButton";
import LoadingBackdrop from "../common/LoadingBackdrop";
import OutlinedButton from "../common/OutlinedButton";
import TextInput from "../common/TextInput";    
import TradePositions from "./TradePositions";
import { DynamicExpiries } from "./BasicStrategyDetails";
import { Add, Check } from "@mui/icons-material";

const filterOptions = createFilterOptions({
    matchFrom: "start",
});

function StrategyTypeConfig({
    strategyDetails,
    setStrategyDetails,
    mode,
    strikePrices,
    expiryDates
}) {
    const [open, setOpen] = useState(false);
    const [positions, setPositions] = useState(null);
    const [loading, setLoading] = useState(false);

    const strategies = [
        {
            name: "Straddle",
            legsDetails: [
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 3,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                },
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 4,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                },
            ],
        },
        {
            name: "Strangle",
            legsDetails: [
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 3,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                },
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 4,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                },
            ],
        },
        {
            name: "Spread",
            legsDetails: [
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 3,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                },
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 3,
                    position: strategyDetails.execution === "buy" ? "sell" : "buy",
                    entryPrice: 0.0,
                },
            ],
            type: "call",
        },
        {
            name: "Fly",
            legsDetails: [
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 3,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                },
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 3,
                    lots: strategyDetails.lots * 2,
                    position: strategyDetails.execution === "buy" ? "sell" : "buy",
                    entryPrice: 0.0,
                },
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 3,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                },
            ],
            directional: "directional",
            type: "call",
        },
        {
            name: "Condor",
            legsDetails: [
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 3,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                },
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 3,
                    position: strategyDetails.execution === "buy" ? "sell" : "buy",
                    entryPrice: 0.0,
                },
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 4,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                },
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 4,
                    position: strategyDetails.execution === "buy" ? "sell" : "buy",
                    entryPrice: 0.0,
                },
            ],
        },
        {
            name: 'OI',
            legsDetails: [
                {
                    strikePrice:
                        strikePrices.strikePrices.length > 0
                            ? strikePrices.nearestStrikePrice
                            : 0,
                    optionType: 4,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                }
            ]
        }
    ];

    const handleStrategySelectedClick = (event) => {
        setStrategyDetails((prevData) => {
            return {
                ...prevData,
                strategySelected: event.target.name,
                type: strategies[event.target.id].type ?? "",
                directional: strategies[event.target.id].directional ?? "",
                ...(event.target.name === "OI Crossover" && (prevData.is_dynamic ? { custom_expiry: expiryDates[0] } : { expiry: expiryDates[0] }))
            };
        });

        setPositions([...strategies[event.target.id].legsDetails]);

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleStrikePriceChange = (event, value) => {
        setPositions((prevData) => {
            var newData = [...prevData];
            newData[event.target.id.split("-")[0]].strikePrice = strategyDetails.is_dynamic ? value : parseInt(value);
            return newData;
        });
    };

    const handleStrategyTypeChange = (event) => {
        setStrategyDetails((prevData) => {
            return { ...prevData, type: event.target.value };
        });

        setPositions((prevData) => {
            var newData = [...prevData];
            newData.forEach((position) => {
                position.optionType = event.target.value === "call" ? 3 : 4;
            });
            return newData;
        });
    };

    const handleDirectionalChange = (event) => {
        setStrategyDetails((prevData) => {
            return { ...prevData, directional: event.target.value };
        })

        event.target.value === "non-directional" ?
            setPositions((prevData) => {
                let newData = [{
                    strikePrice: prevData[0].strikePrice,
                    optionType: 4,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                },
                {
                    strikePrice: prevData[1].strikePrice,
                    optionType: 4,
                    position: strategyDetails.execution === "buy" ? "sell" : "buy",
                    entryPrice: 0.0,
                },
                {
                    strikePrice: prevData[1].strikePrice,
                    optionType: 3,
                    position: strategyDetails.execution === "buy" ? "sell" : "buy",
                    entryPrice: 0.0,
                },
                {
                    strikePrice: prevData[2].strikePrice,
                    optionType: 3,
                    position: strategyDetails.execution,
                    entryPrice: 0.0,
                }]
                return newData;
            }) :
            setPositions([...strategies[3].legsDetails]);
    }

    const handleSave = async () => {
        setLoading(true);
        const { instrument, expiry } = strategyDetails;
        try {
            if (!strategyDetails.is_dynamic) {
                const axiosInstance = createAxiosInstance(true);
                for (let i = 0; i < positions.length; i++) {
                    const quotesResponse = await axiosInstance.post('trades/get-quote/', {
                        name: instrument,
                        expiry: moment(expiry).format('DDMMMYYYY'),
                        put_call: positions[i].optionType === 3 ? 'CALL' : 'PUT',
                        strike: positions[i].strikePrice
                    });

                    if (quotesResponse.status === 200) {
                        setPositions(prevData => {
                            let newData = [...prevData];
                            newData[i].entryPrice = quotesResponse.data.ltp;
                            return newData;
                        })
                    }
                }

                setStrategyDetails((prevData) => {
                    return { ...prevData, positions: positions };
                });
            }
            else {
                setStrategyDetails((prevData) => {
                    return { ...prevData, positions: positions };
                });
            }

            setOpen(false);

        } catch (err) {
            console.log(err.message);
        } finally {
            setLoading(false);
            setOpen(false);
        }
    };

    const handleDynamicStrategyChange = (e) => {
        const { value } = e.target;

        setStrategyDetails(prev => ({
            ...prev,
            is_dynamic: value === "dynamic"
        }))
    }

    const handleAddPosition = () => {
        setPositions(prev => {
            const newValues = [...prev, {
                strikePrice:
                    strikePrices.strikePrices.length > 0
                        ? strikePrices.nearestStrikePrice
                        : 0,
                optionType: 4,
                position: strategyDetails.execution,
                entryPrice: 0.0,
            }]

            return newValues;
        })
    }

    useEffect(() => {
        setPositions(mode === 'edit' ? strategyDetails.positions : positions);
    }, [strategyDetails.positions])

    return (
        <>
            <div className="flex flex-col gap-3 overflow-y-auto border border-primary rounded-md px-4 pt-2 pb-4 w-fit">
                <RadioGroup
                    row
                    name="is_dynamic"
                    sx={{ justifyContent: "center" }}
                    value={strategyDetails.is_dynamic ? "dynamic" : "custom"}
                    onChange={handleDynamicStrategyChange}
                >
                    <FormControlLabel
                        sx={{ fontSize: '14px' }}
                        value="custom"
                        disableTypography
                        disabled={mode === 'edit'}
                        control={
                            <Radio
                                color="primary"
                                size="small"
                            />
                        }
                        label="Custom"
                    />
                    <FormControlLabel
                        sx={{ fontSize: '14px' }}
                        value="dynamic"
                        disableTypography
                        disabled={mode === 'edit'}
                        control={
                            <Radio
                                color="primary"
                                size="small"
                            />
                        }
                        label="Dynamic"
                    />
                </RadioGroup>
                <div className="flex flex-col gap-2 w-1/2">
                    <label className="font-medium text-sm">
                        Select expiry<span className="text-red-700">*</span>
                    </label>
                    <Autocomplete
                        value={strategyDetails.is_dynamic ? strategyDetails.custom_expiry : strategyDetails.expiry}
                        openOnFocus
                        size="small"
                        options={expiryDates}
                        getOptionLabel={(option) => strategyDetails.is_dynamic ? DynamicExpiries[option] : option}
                        onChange={(event, value) => {
                            setStrategyDetails((prevData) => {
                                return { ...prevData, [strategyDetails.is_dynamic ? "custom_expiry" : "expiry"]: value };
                            });
                        }}
                        clearOnEscape
                        clearOnBlur
                        noOptionsText="No expiries"
                        autoComplete
                        renderInput={(params) => (
                            <TextInput {...params} placeholder="Select an expiry date" />
                        )}
                    />
                </div>

                <div className="flex flex-col gap-3">
                    <h1 className="font-medium text-sm">Select Strategy</h1>
                    <div className="flex gap-5">
                        {strategies.map((strategy, idx) => {
                            return (
                                <OutlinedButton
                                    key={idx}
                                    id={idx}
                                    variant="outlined"
                                    name={strategy.name}
                                    onClick={handleStrategySelectedClick}
                                    startIcon={strategyDetails.strategySelected === strategy.name &&
                                        <Check fontSize="small" color="success" />
                                    }
                                    disabled={strategy.name === "OI" ? !["NIFTY", "BANKNIFTY"].includes(strategyDetails.instrument) : false}
                                >
                                    {strategy.name}
                                </OutlinedButton>
                            );
                        })}
                    </div>
                </div>

                {positions && positions.length > 0 && (
                    <Dialog
                        open={open}
                        fullWidth
                        maxWidth="xs"
                        sx={{ "& .MuiDialogContent-root": { padding: "1em 3em" } }}
                    >
                        <DialogTitle sx={{ fontFamily: "Poppins", fontWeight: 600 }}>
                            Configure Legs
                        </DialogTitle>
                        <DialogContent dividers>
                            <div className="w-full flex flex-col gap-3 items-center justify-center">
                                {strategyDetails.strategySelected === "Fly" && (
                                    <RadioGroup row value={strategyDetails.directional} onChange={handleDirectionalChange}>
                                        <FormControlLabel
                                            value="directional"
                                            disableTypography
                                            control={
                                                <Radio
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label="Directional"
                                        />
                                        <FormControlLabel
                                            value="non-directional"
                                            disableTypography
                                            control={
                                                <Radio
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label="Non-Directional"
                                        />
                                    </RadioGroup>
                                )}
                                {strategyDetails.strategySelected === "Spread" ||
                                    (strategyDetails.strategySelected === "Fly" && strategyDetails.directional === "directional") ? (
                                    <RadioGroup
                                        row
                                        value={strategyDetails.type}
                                        onChange={handleStrategyTypeChange}
                                    >
                                        <FormControlLabel
                                            value="call"
                                            disableTypography
                                            control={
                                                <Radio
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label="Call"
                                        />
                                        <FormControlLabel
                                            value="put"
                                            disableTypography
                                            control={
                                                <Radio
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label="Put"
                                        />
                                    </RadioGroup>
                                ) : (
                                    ""
                                )}
                                {positions.map((position, idx) => {
                                    return (
                                        <div key={idx} className="flex items-center gap-7 w-full">
                                            <h1 className="font-semibold text-lg">
                                                {position.position === "buy" ? "+" : "-"}
                                                {position.lots ? position.lots : strategyDetails.lots}X
                                            </h1>
                                            <Autocomplete
                                                value={position.strikePrice.toString()}
                                                id={`${idx}`}
                                                openOnFocus
                                                disableClearable
                                                size="small"
                                                options={strikePrices.strikePrices.map((strikePrice) =>
                                                    strikePrice.toString()
                                                )}
                                                onChange={handleStrikePriceChange}
                                                clearOnBlur
                                                fullWidth
                                                filterOptions={filterOptions}
                                                noOptionsText="No strike price"
                                                renderInput={(params) => (
                                                    <TextInput
                                                        {...params}
                                                        placeholder="Select a strike price"
                                                        helperText={`Select ${position.position === "buy" ? "Long" : "Short"
                                                            } strike`}
                                                    />
                                                )}
                                            />
                                            <h1 className="font-semibold text-lg">
                                                {position.optionType === 3 ? "CE" : "PE"}
                                            </h1>

                                            {strategyDetails.strategySelected === "OI Crossover" && idx === positions.length - 1 &&
                                                <IconButton size="small" style={{ backgroundColor: "#3E4784" }} onClick={handleAddPosition}>
                                                    <Add sx={{ color: "#FFFFFF" }} />
                                                </IconButton>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <ColoredButton
                                variant="contained"
                                size="small"
                                onClick={handleSave}
                            >
                                Save
                            </ColoredButton>
                            <ColoredButton
                                variant="contained"
                                size="small"
                                onClick={handleClose}
                            >
                                Close
                            </ColoredButton>
                        </DialogActions>
                    </Dialog>
                )}

                {strategyDetails.strategySelected !== "" &&
                    !open && (
                        <TradePositions
                            strategyDetails={strategyDetails}
                            toggleDialog={setOpen}
                        />
                    )}
            </div>

            {loading && <LoadingBackdrop loading={loading} />}
        </>
    );
}

export default StrategyTypeConfig;
