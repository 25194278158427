import "./Styles/App.css";
import RoutesSetup from "./RoutesSetup";

function App() {
  return (
    <RoutesSetup />
  );
}

export default App;
