import styled from '@emotion/styled'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import OutlinedButton from './OutlinedButton'
import ColoredButton from './ColoredButton'
import { Close } from '@mui/icons-material'


const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        borderRadius: '12px',
        padding: '16px 5px',
        width: '450px',
        maxHeight: '550px'
    },
    '& .MuiDialogTitle-root': {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 600,
    },
    '& .MuiDialogTitle-root .icon-shadow': {
        borderRadius: '50%',
        background: '#EAECF5',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& .MuiDialogContent-root': {
        fontWeight: 400,
        paddingBottom: '16px'
    },
    '& .MuiDialogActions-root': {
        padding: '16px 24px'
    },
    '& .MuiDialogActions-root button': {
        width: '100%'
    }
}))


const DialogBoxWrapper = ({ open, setOpen, iconComp, title, subtitle, contentComponent, confirmHandler, displayActions = true}) => {
    return (
        <StyledDialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                <div className='flex justify-between'>
                    <div className='icon-shadow'>
                        {iconComp}
                    </div>
                    <IconButton size='small' onClick={() => setOpen(false)}>
                        <Close fontSize='small' />
                    </IconButton>
                </div>
                <h1 className="text-lg font-semibold mt-5">{title}</h1>
                {subtitle && <p className="text-gray-500 text-sm font-normal mt-2">{subtitle}</p>}
            </DialogTitle>
            <DialogContent>
                {contentComponent}
            </DialogContent>
            {displayActions && <DialogActions>
                <OutlinedButton variant='outlined' onClick={() => setOpen(false)}>Cancel</OutlinedButton>
                <ColoredButton variant='contained' onClick={confirmHandler}>Confirm</ColoredButton>
            </DialogActions>}
        </StyledDialog>
    )
}

export default DialogBoxWrapper;