import { Email, Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import login_illustration from "../assets/login_illustration.png";
import logo from "../assets/tradebuddys_logo.png";
import ColoredButton from "../components/common/ColoredButton";
import LoadingBackdrop from "../components/common/LoadingBackdrop";
import TextInput from "../components/common/TextInput";
import "../Styles/Transitions.css";
import { createAxiosInstance } from "../Utils/axiosConfig";
import useToken from "../Utils/useToken";

// making changes for disabling otp system.

// let currentOtpIndex = 0;

function Login() {
    const { setToken } = useToken();
  const [showPassword, setShowPassword] = useState(false);

  const [inputLoginDetails, setInputLoginDetails] = useState({
    email: "",
    password: "",
  });

  const [isError, setIsError] = useState({
    email: false,
    password: false,
  });

  const [helperText, setHelperText] = useState({
    emailHelperText: "",
    passwordHelperText: "",
  });

  //   const [otp, setOtp] = useState(new Array(4).fill(""));

  //   const [activeOtpIndex, setActiveOtpIndex] = useState(0);

  //   const [otpSent, setOtpSent] = useState(false);

  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState({ msg: "", type: "" });

  //   const inputRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  //   const onSendOtpClick = async (event) => {
  //     event.preventDefault();
  //     if (isError.email || isError.password) {
  //       return;
  //     } else {
  //       setLoading(true);
  //       var response = await sendOtp(inputLoginDetails.email);
  //       if (response.status === 400) {
  //         setLoading(false);
  //         await response
  //           .json()
  //           .then((details) =>
  //             setMsg({ ...msg, msg: details.detail, type: "error" })
  //           );
  //       } else if (response.status === 200) {
  //         setLoading(false);
  //         await response
  //           .json()
  //           .then((details) =>
  //             setMsg({ ...msg, msg: details.detail, type: "success" })
  //           );
  //         setOtpSent(true);
  //       } else if (response.status === 500) {
  //         setLoading(false);
  //         setMsg({
  //           ...msg,
  //           msg: "User with email does not exist",
  //           type: "error",
  //         });
  //       }
  //     }
  //   };

  const onLoginBtnClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMsg({msg: '', type: ''});
    try {
        const axiosInstanceWithoutAuth = createAxiosInstance(false);
        const response = await axiosInstanceWithoutAuth.post('auth/login/', inputLoginDetails);

        if(response.status === 200)
        {
            setToken({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
            });
            localStorage.setItem("loggedInUser", JSON.stringify(response.data.user));

            const axiosInstance = createAxiosInstance(true);
            const userResponse = await axiosInstance.get('auth/user/', {
                headers: {Authorization: 'Bearer ' + JSON.parse(localStorage.getItem("userAuthToken"))?.userToken?.accessToken}
            });

            if(userResponse.status === 200)
            {
                localStorage.setItem("userDetails", JSON.stringify(userResponse.data));
                setMsg({msg: "User logged in successfully", type: "success"});
            }

            setTimeout(() => {
                navigate(from, {replace: true});
            }, 1500);
        }
    } catch (err) {
        let msg = '';
        if(err.request.status === 404)
            msg = err.message;
        else if(err.request.status === 401 || err.request.status === 400)
            msg = err.response.data.detail;
        setMsg({ msg: msg, type: "error" });
    } finally {
      setLoading(false);
    }
  };

  //   const onVerifyOtpClick = async (event) => {
  //     event.preventDefault();
  //     setLoading(true);
  //     var response = await loginUser(inputLoginDetails, otp.join(""));
  //     if (response.status === 400) {
  //       setLoading(false);
  //       await response
  //         .json()
  //         .then((details) =>
  //           setMsg({ ...msg, msg: details.detail, type: "error" })
  //         );
  //     } else if (response.status === 200) {
  //       await response.json().then((data) => {
  //         setToken({
  //           accessToken: data.access_token,
  //           refreshToken: data.refresh_token,
  //         });
  //         localStorage.setItem("loggedInUser", JSON.stringify(data.user));
  //       });
  //       var userRes = await getUserDetails();
  //       setLoading(false);
  //       if (userRes === 400) {
  //         setMsg({ ...msg, msg: "User not found", type: "error" });
  //       } else if (userRes.status === 200) {
  //         await userRes
  //           .json()
  //           .then((data) =>
  //             localStorage.setItem("userDetails", JSON.stringify(data))
  //           );
  //         setMsg({ ...msg, msg: "User logged in successfully", type: "success" });

  //         setTimeout(() => {
  //           navigate("/home");
  //         }, 2000);
  //       } else {
  //         setMsg({ ...msg, msg: "Some error has occurred", type: "error" });
  //       }
  //     } else {
  //       setLoading(false);
  //       setMsg({ ...msg, msg: "Invalid user credentials", type: "error" });
  //     }
  //   };

  const onUserInputChange = (event) => {
    const { name, value } = event.target;

    setInputLoginDetails((prevFormData) => {
      return { ...prevFormData, [name]: value };
    });
  };

  //   const onOtpInputChange = (event) => {
  //     const { value } = event.target;
  //     const newOtp = [...otp];
  //     newOtp[currentOtpIndex] = value;

  //     if (!value) setActiveOtpIndex(currentOtpIndex - 1);
  //     else setActiveOtpIndex(currentOtpIndex + 1);
  //     setOtp(newOtp);
  //   };

  //   const handleOnKeyDown = ({ key }, index) => {
  //     currentOtpIndex = index;
  //     if (key === "Backspace") {
  //       setActiveOtpIndex(currentOtpIndex - 1);
  //     }
  //   };

  const validationController = (event) => {
    const { name, value } = event.target;

    setIsError((prevData) => {
      return { ...prevData, [name]: validateUserInput(name, value) };
    });
  };

  const validateUserInput = (name, value) => {
    // if (name === "email") {
    //   if (value === "") {
    //     setHelperText((prevData) => {
    //       return { ...prevData, emailHelperText: "Email is required" };
    //     });
    //     return true;
    //   } else if (!value.match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/)) {
    //     setHelperText((prevData) => {
    //       return { ...prevData, emailHelperText: "Invalid email address" };
    //     });
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }

    if (name === "password") {
      if (value === "") {
        setHelperText((prevData) => {
          return { ...prevData, passwordHelperText: "Password is required" };
        });
        return true;
      } else if (!value.match(/[^ ]{6}/)) {
        setHelperText((prevData) => {
          return {
            ...prevData,
            passwordHelperText: "Password must have at least 6 characters",
          };
        });
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  //   useEffect(() => {
  //     inputRef.current?.focus();
  //   }, [activeOtpIndex]);

    useEffect(() => {
        setIsError(prevData => {
            return {...prevData, email: false}
        });
        setHelperText(prevData => {
            return {...prevData, emailHelperText: ""}
        });
    }, [inputLoginDetails.email]);

    useEffect(() => {
        setIsError(prevData => {
            return {...prevData, password: false}
        });
        setHelperText(prevData => {
            return {...prevData, passwordHelperText: ""}
        });
    }, [inputLoginDetails.password]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-4">
      <div className="flex flex-col h-screen col-span-1 md:col-span-2">
        <div className="flex items-center px-6 pt-5">
          <img src={logo} alt="logo" className="mr-5" height="50px" width="50px" />
          <h1 className="text-xl md:text-2xl font-normal">Trade Buddys</h1>
        </div>
        <div className="flex flex-auto justify-center items-center">
          <div className="w-4/5 lg:w-2/4 flex flex-col items-center">
            <h1 className="text-3xl md:text-3xl lg:text-5xl text-center font-semibold mb-2.5">
              Welcome back
            </h1>
            <p className="text-sm md:text-base font-light text-[#333333]">
              To login please enter your credentials
            </p>
            <form className="w-full px-5 mt-4" onSubmit={onLoginBtnClick}>
              {msg.msg ? <Alert severity={msg.type}>{msg.msg}</Alert> : null}
              <div className="flex flex-col mb-5 mt-1">
                <label htmlFor="email" className="text-base md:text-xl mb-2">
                  Email<span className="text-red-700">*</span>
                </label>
                <TextInput
                  error={isError.email}
                  autoFocus
                  name="email"
                  value={inputLoginDetails.email}
                  //   disabled={otpSent}
                  placeholder="Enter email address"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  onChange={onUserInputChange}
                  helperText={isError.email ? helperText.emailHelperText : ""}
                  onBlur={validationController}
                  required
                />
              </div>
              <div className="flex flex-col mb-5">
                <label htmlFor="password" className="text-base md:text-xl mb-2">
                  Password<span className="text-red-700">*</span>
                </label>
                <TextInput
                  error={isError.password}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={inputLoginDetails.password}
                  //   disabled={otpSent}
                  placeholder="Enter Password"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  onChange={onUserInputChange}
                  helperText={
                    isError.password ? helperText.passwordHelperText : ""
                  }
                  onBlur={validationController}
                  required
                />
              </div>
              <p className="text-sm font-light text-primary mb-5">
                <Link to="/signUp">Forgot Password?</Link>
              </p>
              <ColoredButton
                type="submit"
                className="hover:scale-105"
                fullWidth
                variant="contained"
                size="small"
                onClick={onLoginBtnClick}
                // onClick={onSendOtpClick}
                // disabled={otpSent}
              >
                LOGIN
                {/* Send OTP */}
              </ColoredButton>
              <p className="text-center text-sm font-light text-[#333333] mt-6">
                Don't have an account?{" "}
                <Link className="text-primary" to="/signUp">
                  Sign Up
                </Link>
              </p>
            </form>
            {/* <div
              className={
                otpSent ? "fade-in-transition px-5 mt-5" : "hidden px-5 mt-5"
              }
            >
              <form className="flex flex-col" onSubmit={onVerifyOtpClick}>
                <label htmlFor="otp" className="text-base md:text-xl mb-2">
                  Enter OTP<span className="text-red-700">*</span>
                </label>
                <div className="flex lg:w-8/12 gap-4">
                  {otp.map((item, index) => {
                    return (
                      <TextInput
                        autoComplete="off"
                        inputRef={index === activeOtpIndex ? inputRef : null}
                        key={index}
                        value={otp[index]}
                        sx={{
                          "& .MuiInputBase-input": { textAlign: "center" },
                        }}
                        size="small"
                        variant="outlined"
                        placeholder="X"
                        inputProps={{ maxLength: 1 }}
                        onChange={onOtpInputChange}
                        onKeyDown={(event) => handleOnKeyDown(event, index)}
                      />
                    );
                  })}
                </div>
                <p className="text-sm font-light text-[#333333] my-3">
                  Haven't received OTP?{" "}
                  <span className="text-primary" onClick={onSendOtpClick}>
                    Resend OTP
                  </span>
                </p>
                <ColoredButton
                  type="submit"
                  className="hover:scale-105"
                  variant="contained"
                  size="small"
                  onClick={onVerifyOtpClick}
                >
                  Verify OTP
                </ColoredButton>
              </form>
            </div> */}
          </div>
        </div>
      </div>
      <div className="h-screen bg-[#F3F5F9] hidden col-span-2 md:flex justify-center items-center">
        <img src={login_illustration} alt="Login illustration" />
      </div>
      
    {loading && <LoadingBackdrop loading={loading} />}

    </div>
  );
}

export default Login;
