import { Chip } from '@mui/material'
import React from 'react'

const NavTab = ({ isActive = false, title, chipText, tabId, setActiveTab }) => {
    return (
        <div className={`flex items-center gap-2 py-2 px-6 ${isActive && 'rounded-3xl border-2 border-[#AFB5D9]'} cursor-pointer`} onClick={() => setActiveTab(tabId)}>
            <h1 className='text-lg font-medium'>{title}</h1>
            {isActive && chipText && <Chip label={chipText} size='small' />}
        </div>
    )
}

export default NavTab