import { createContext, useContext, useEffect, useState } from "react";
import useToken from "./useToken";
import { Outlet } from "react-router-dom";
import FeedbackSnackbar from "../components/common/FeedbackSnackbar";

const WebSocketContext = createContext(null);

export default () => {

    const [ws, setWs] = useState(null);

    const { token } = useToken();

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const [displayMsg, setDisplayMsg] = useState({
        type: "info",
        msg: "",
    });

    const receiveNotificationsData = (_ws) => {
        _ws.onmessage = ({ data }) => {
            const notification = JSON.parse(data);
            if (notification.type === "notif") {
                setDisplayMsg({
                    msg: notification.head + ": " + notification.body,
                    type: "info"
                });
                setSnackBarOpen(true);
            }
        }
    }

    useEffect(() => {
        const _ws = new WebSocket(`${process.env.REACT_APP_WS_CONN}?token=${token.userToken.accessToken}`)
        setWs(_ws);

        setTimeout(() => receiveNotificationsData(_ws), 100);

        return () => {
            if (ws != null && ws.readyState === WebSocket.OPEN)
                ws.close()
        }
    }, [])

    return (
        <WebSocketContext.Provider value={{ws, setWs}}>
            <Outlet />
            {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />}
        </WebSocketContext.Provider>
    )
}

export const useWebSocketContext = () => useContext(WebSocketContext);