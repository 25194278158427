import React, { useEffect } from 'react'
import TextInput from '../common/TextInput'

const OpenStrategyEditDialog = ({ strategy, profitability, setProfitability }) => {

    const handleChange = (e) => {
        setProfitability(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    useEffect(() => {
        setProfitability(prev => ({
            ...prev,
            target: strategy.target,
            sl: strategy.sl
        }))
    }, [])

  return (
    <div className='flex justify-between gap-4'>
        <div className='flex flex-col gap-1'>
            <span className="text-sm font-semibold">Current Target Delta: </span>
            <span className='text-xs font-normal'>{strategy.target}</span>
            <div className='mt-2'>
                <label className="text-sm font-semibold">New Target Delta:</label>
                <TextInput name='target' variant='outlined' size='small' value={profitability.target} onChange={handleChange} />
            </div>
        </div>
        <div className='flex flex-col gap-1'>
            <span className="text-sm font-semibold">Current Stoploss Delta: </span>
            <span className='text-xs font-normal'>{strategy.sl}</span>
            <div className='mt-2'>
                <label className="text-sm font-semibold">New Stoploss Delta:</label>
                  <TextInput name='sl' variant='outlined' size='small' value={profitability.sl} onChange={handleChange} />
            </div>
        </div>
    </div>
  )
}

export default OpenStrategyEditDialog