import { CheckCircle } from "@mui/icons-material";
import React from "react";
import "../Styles/CustomStyles.css";
import benefits_banner from "../assets/benefits_banner.png";
import curved_line from "../assets/curved_line.png";
import feature_1 from "../assets/features_1.png";
import feature_2 from "../assets/features_2.png";
import feature_3 from "../assets/features_3.png";
import feature_6 from "../assets/features_6.png";
import main_banner from "../assets/main_banner1.png";
import ColoredButton from "../components/common/ColoredButton";

function Index() {
  return (
    <>
        <section className="px-32 py-12 flex justify-between">
          <div>
            <h1 className="font-bold text-6xl leading-[73px] w-[600px]">
              Automation tool to find, manage and exit trades
            </h1>
            <img src={curved_line} alt="Curved line" />
            <h1 className="w-3/4 my-7 font-medium leading-8">
              Finding high probability single leg and multi leg trades, managing them while in execution and exiting them based on your set criteria.
              The platform can handle multiple brokers and multiple accounts within each broker
            </h1>
            <ColoredButton sx={{ borderRadius: "40px" }} variant="contained">
              Try free trial
            </ColoredButton>
          </div>
          <img src={main_banner} alt="Main banner" className="h-[500px] w-[626px]" />
        </section>

        <div className="landing_page_bg-2 py-20">
            <section className="px-32 pb-20">
            <div className="flex justify-between">
                <h1 className="font-bold text-4xl leading-[50px]">
                Our Features
                <br /> you can get
                </h1>
                <p className="text-[#A6A6A6] font-medium leading-8">
                We offer a variety of interesting features that 
                <br /> can automate your trading journey 
                <br /> and help you manage your trades effortlessly
                </p>
                <ColoredButton
                sx={{ borderRadius: "40px" }}
                variant="contained"
                className="h-fit self-center"
                >
                Get started
                </ColoredButton>
            </div>
            <div className="grid grid-cols-3 gap-x-11 gap-y-6 mt-16">
                <div className="flex flex-col gap-6">
                <img src={feature_1} alt="Feature 1" className="h-auto w-auto" />
                <h1 className="font-semibold text-3xl">
                    Multi-leg options strategy  
                </h1>
                <p className="leading-7 font-medium text-[#A6A6A6]">
                    Lets you create, manage and automate multi leg options strategies with ease.
                </p>
                </div>
                <div className="flex flex-col gap-6">
                <img src={feature_2} alt="Feature 2" className="h-auto w-auto" />
                <h1 className="font-semibold text-3xl">Multiple brokers & multiple accounts</h1>
                <p className="leading-7 font-medium text-[#A6A6A6]">
                    Execute trades on multiple brokers like Zerodha, Shoonya & Angel. Multiple accounts in each of these brokers can be used at the same time to execute trades.
                </p>
                </div>
                <div className="flex flex-col gap-6">
                <img src={feature_3} alt="Feature 3" className="h-auto w-auto" />
                <h1 className="font-semibold text-3xl">Comprehensive Market Insights Dashboard</h1>
                <p className="leading-7 font-medium text-[#A6A6A6]">
                    Dashboard that displays relevant data like options OI, trending sectors, gainers and losers, etc. to help the traders gauge the market.
                </p>
                </div>
                <div className="flex flex-col gap-6">
                    <img src={feature_3} alt="Feature 3" className="h-auto w-auto" />
                    <h1 className="font-semibold text-3xl">Order splitting for large trades</h1>
                    <p className="leading-7 font-medium text-[#A6A6A6]">
                        Large orders are divided into smaller ones to minimize market impact and optimize execution efficiency.
                    </p>
                </div>
                <div className="flex flex-col gap-6">
                    <img src={feature_3} alt="Feature 3" className="h-auto w-auto" />
                    <h1 className="font-semibold text-3xl">Execute paper trades</h1>
                    <p className="leading-7 font-medium text-[#A6A6A6]">
                        Capability to simulate trades without real money, allowing practice and strategy testing in real-time markets
                    </p>
                </div>
                <div className="flex flex-col gap-6">
                    <img src={feature_6} alt="Feature 3" className="h-auto w-auto" />
                    <h1 className="font-semibold text-3xl">Smart Reporting</h1>
                    <p className="leading-7 font-medium text-[#A6A6A6]">
                        Trade book and order book to segregate trades and orders by strategy or broker or account 
                    </p>
                </div>
            </div>
            </section>
            <section className="px-32 flex justify-between gap-10">
            <div>
                <h1 className="font-bold text-4xl leading-[50px]">
                What Benefits Will You Get
                </h1>
                <div className="flex flex-col gap-9 mt-12">
                <div className="flex gap-5 items-center">
                    <CheckCircle sx={{ color: "#3E4784" }} fontSize="small" />
                    <h1 className="font-medium text-lg">
                        Speciality scanner designed to throw up high probability trades
                    </h1>
                </div>
                <div className="flex gap-5 items-center">
                    <CheckCircle sx={{ color: "#3E4784" }} fontSize="small" />
                    <h1 className="font-medium text-lg">
                        Automated multi leg option strategy
                    </h1>
                </div>
                <div className="flex gap-5 items-center">
                    <CheckCircle sx={{ color: "#3E4784" }} fontSize="small" />
                    <h1 className="font-medium text-lg">
                    Strategy execution on multiple accounts with multiple brokers
                    </h1>
                </div>
                <div className="flex gap-5 items-center">
                    <CheckCircle sx={{ color: "#3E4784" }} fontSize="small" />
                    <h1 className="font-medium text-lg">Large orders are broken up into smaller size trades</h1>
                </div>
                <div className="flex gap-5 items-center">
                    <CheckCircle sx={{ color: "#3E4784" }} fontSize="small" />
                    <h1 className="font-medium text-lg">
                        Access to real time market insights 
                    </h1>
                </div>
                <div className="flex gap-5 items-center">
                    <CheckCircle sx={{ color: "#3E4784" }} fontSize="small" />
                    <h1 className="font-medium text-lg">
                        Execute trades originating from trading platforms like TradingView or ChartInk
                    </h1>
                </div>
                </div>
            </div>
            <div className="flex items-center">
                <img
                src={benefits_banner}
                alt="Benefits Banner"
                className="h-auto w-auto"
                />
            </div>
            </section>
        </div>
      </>
  );
}

export default Index;
