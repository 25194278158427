import { ArrowForward, Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material'
import { IconButton, TextField } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
      <section className="bg-[#161C28] pt-[170px] pb-[100px] px-[130px]">
          <div className="flex justify-between">
              <div className="flex flex-col gap-7">
                  <span className="text-primary font-semibold text-5xl">Trade Buddys</span>
                  <span className="font-medium text-lg text-[#A6A6A6]">Send your queries and we will get back to you</span>
                  <TextField variant="outlined" size="small" sx={{ "& .MuiOutlinedInput-root": { color: "#A6A6A6", borderRadius: "71px", border: "1px solid #A6A6A6", "& .MuiOutlinedInput-notchedOutline": { borderWidth: 0 } }, "& .MuiOutlinedInput-input": { '&::placeholder': { color: "#A6A6A6" } } }} placeholder="Enter your email address" InputProps={{ endAdornment: <IconButton size="small" sx={{ backgroundColor: "#3E4784 !important" }}><ArrowForward sx={{ color: "#FFFFFF" }} fontSize="small" /></IconButton> }} />
              </div>

              <div className="flex gap-8">
                  <div className="flex flex-col gap-6">
                      <Link to="/about"><span className="text-lg font-medium text-[#A6A6A6]">About</span></Link>
                      <Link to="/pricing"><span className="text-lg font-medium text-[#A6A6A6]">Pricing</span></Link>
                      <Link to="/faq"><span className="text-lg font-medium text-[#A6A6A6]">FAQs</span></Link>
                      <Link to="/login"><span className="text-lg font-medium text-[#A6A6A6]">Login</span></Link>
                      <Link to="/signup"><span className="text-lg font-medium text-[#A6A6A6]">Sign up</span></Link>
                  </div>

                  <div className="flex flex-col gap-6">
                      <Link to="">
                          <Instagram fontSize="small" color="primary" />
                          <span className="text-lg ml-6 font-medium text-[#A6A6A6] align-middle">Instagram</span>
                      </Link>
                      <Link to="">
                          <Twitter fontSize="small" color="primary" />
                          <span className="text-lg ml-6 font-medium text-[#A6A6A6] align-middle">Twitter</span>
                      </Link>
                      <Link to="">
                          <YouTube fontSize="small" color="primary" />
                          <span className="text-lg ml-6 font-medium text-[#A6A6A6] align-middle">Youtube</span>
                      </Link>
                      <Link to="">
                          <LinkedIn fontSize="small" color="primary" />
                          <span className="text-lg ml-6 font-medium text-[#A6A6A6] align-middle">LinkedIn</span>
                      </Link>
                  </div>
              </div>
          </div>
      </section>
  )
}

export default Footer