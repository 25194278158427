import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FeedbackSnackbar from "../components/common/FeedbackSnackbar";
import LoadingBackdrop from "../components/common/LoadingBackdrop";
import { createAxiosInstance } from "../Utils/axiosConfig";

function ZerodhaRedirectPage() {

    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [displayMsg, setDisplayMsg] = useState({type: '', msg: ''});
    const routeParams = useParams();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        var requestToken = params.get('request_token');
        var brokerId = routeParams.brokerId;

        async function loginZerodhaBroker() {
            try {
                setLoading(true);
                const axiosInstance = createAxiosInstance(true);
                const response = await axiosInstance.post(`trades/zerodha/login?refresh_token=${requestToken}&userid=${brokerId}`);
                if (response.status === 201) {
                    setSnackBarOpen(true);
                    setDisplayMsg({ type: 'success', msg: 'Broker account login successful' });
                }
            } catch (err) {
                setSnackBarOpen(true);
                setDisplayMsg({ type: 'error', msg: 'Broker account login failed' });
            } finally {
                setLoading(false);
                setTimeout(() => {
                    navigate('/brokerAccountsSetup');
                }, 2000);
            }
        }

        loginZerodhaBroker();

    }, []);

    if(loading) {
        return (
            <LoadingBackdrop loading={loading} />
        )
    } else if(snackBarOpen) {
        return (
            <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />
        )
    }
}

export default ZerodhaRedirectPage;