import { Edit } from "@mui/icons-material";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React from "react";
import StyledTableCell from "../common/StyledTableCell";

function TradePositions({ strategyDetails, toggleDialog }) {
  const handleEditClick = () => {
    toggleDialog(true);
  };

  return (
    <TableContainer component={Paper} className="mt-3">
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Position</StyledTableCell>
            <StyledTableCell align="center">Strike Price</StyledTableCell>
            <StyledTableCell align="center">Expiry</StyledTableCell>
            {!strategyDetails.is_dynamic && <StyledTableCell align="center">Entry Price</StyledTableCell>}
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {strategyDetails.positions?.map((position, idx) => {
            return (
              <TableRow key={idx}>
                <StyledTableCell align="center">
                  {position.position === "buy" ? "+" : "-"}
                  {position.lots ?? strategyDetails.lots}x
                </StyledTableCell>
                <StyledTableCell align="center">
                  {strategyDetails.is_dynamic ? `(${position.strikePrice}) ` : position.strikePrice}
                  {position.optionType === 3 ? "CE" : "PE"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {strategyDetails.expiry}
                </StyledTableCell>
                {!strategyDetails.is_dynamic && 
                    <StyledTableCell align="center">
                        Rs. {(position?.entryPrice * (position.lots ?? strategyDetails.lots)).toFixed(2)}
                    </StyledTableCell>
                }
                <StyledTableCell>
                  <IconButton size="small" onClick={handleEditClick}>
                    <Edit sx={{ color: "black" }} />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TradePositions;
