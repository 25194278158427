import { MarkChatRead, NotificationsNone } from '@mui/icons-material'
import { Badge, Button, IconButton, Popover, styled } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import NavTab from '../common/NavTab'
import { createAxiosInstance } from '../../Utils/axiosConfig';

const StyledNotificationsPanel = styled(Popover)({
    "& .MuiPaper-root": {
        borderRadius: '8px',
        boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
        minWidth: '450px'
    }
})

const Notifications = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const showNotifications = Boolean(anchorEl);
    const [notifications, setNotifications] = useState([]);
    const [notificationsCountInBadge, setNotificationCountInBadge] = useState(0); 

    const [activeTab, setActiveTab] = useState(0);

    const handleOpenNotifications = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

   
    
    useEffect(() => {
        async function getNotification() {
            try {
                const axiosInstance = createAxiosInstance(true);
                const notificationsResponse = await axiosInstance.get('/notification/');
                setNotifications(notificationsResponse.data);
                setNotificationCountInBadge(notificationsResponse.data.length);
            } catch (err) {
                console.log(err);
            }
            
        }
        getNotification();
    }, []);

  return (
    <>
        <Badge badgeContent={notificationsCountInBadge} color='primary'>
            <div className="px-4 py-2.5s rounded-lg border border-[#D0D5DD] content-center cursor-pointer hover:bg-[#D0D5DD]" onClick={handleOpenNotifications}>
                <NotificationsNone sx={{ color: '#344054' }} />
            </div>
        </Badge>

        <StyledNotificationsPanel open={showNotifications} anchorEl={anchorEl} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} transformOrigin={{horizontal: 'left', vertical: 'top'}} onClose={handleClose}>
            <div className='flex justify-between py-5 px-6 items-center'>
                <span className='text-lg font-medium text=[#101828]'>Notifications</span>
                <Button variant='outlined' size='small'>Read All</Button>
            </div>
            <div className='flex gap-3 px-6 mb-2'>
                <NavTab tabId={0} isActive={activeTab === 0} title="Trade" chipText={notifications.length} setActiveTab={setActiveTab} />
                <NavTab tabId={1} isActive={activeTab === 1} title="System" setActiveTab={setActiveTab} />
            </div>
            <div className='grid grid-cols-8 divide-y-2'>
                <div className='bg-[#F9FAFB] px-6 py-2 items-center col-span-7 !border-y-0'>
                    <span className='text-xs font-medium text-[#667085]'>Notifications</span>
                </div>
                <div className='bg-[#F9FAFB] !border-y-0'></div>

                {notifications.map((notification, index) => {
                    return (
                        <Fragment key={index}>
                            <div className='col-span-7 border-y-0 py-2 px-6'>
                                <div className='flex items-center gap-3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10.832 1.66602H4.9987C4.55667 1.66602 4.13275 1.84161 3.82019 2.15417C3.50763 2.46673 3.33203 2.89065 3.33203 3.33268V16.666C3.33203 17.108 3.50763 17.532 3.82019 17.8445C4.13275 18.1571 4.55667 18.3327 4.9987 18.3327H14.9987C15.4407 18.3327 15.8646 18.1571 16.1772 17.8445C16.4898 17.532 16.6654 17.108 16.6654 16.666V7.49935M10.832 1.66602L16.6654 7.49935M10.832 1.66602V7.49935H16.6654" stroke="#3E4784" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span className='text-sm font-medium text-[#101828]'>{notification.head}</span>
                                </div>
                                <span className='text-[10px] text-[#101828]'>{notification.body}</span>
                            </div>

                            <div className='flex justify-center'>
                                <IconButton size='small'>
                                   <MarkChatRead fontSize='small' />
                                </IconButton>
                            </div>
                        </Fragment>
                    )
                })}
            </div>
        </StyledNotificationsPanel>
        
    </>
  )
}

export default Notifications