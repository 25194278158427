import { Box, Modal } from '@mui/material'
import React, { useState } from 'react'
import ColoredButton from './ColoredButton';

const RiskDisclosurePopup = () => {
    const [open, setOpen] = useState(localStorage.getItem('user-risk-disclosure') === null);
    const handleClick = () => {
        localStorage.setItem('user-risk-disclosure', true);
        setOpen(false);
    }
    return (
        <Modal open={open}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <div className='flex flex-col items-center justify-center'>
                    <h1 className='text-lg font-semibold'>Risk Disclosures on Derivatives</h1>
                    <p className='mt-2 mb-4'>9 out of 10 individual traders in the equity Futures and Options (F&O) segment incurred net losses.
                        On average, the loss-making traders registered a net trading loss close to ₹50,000.
                        Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading losses as transaction costs.
                        Those making net trading profits incurred between 15% to 50% of such profits as transaction costs.
                        Source:
                        SEBI study dated January 25, 2023, on "Analysis of Profit and Loss of Individual Traders dealing in equity Futures and Options (F&O) Segment," wherein Aggregate Level findings are based on annual Profit/Loss incurred by individual traders in equity F&O during FY 2021-22.
                    </p>
                    <ColoredButton variant='contained' size='small' onClick={handleClick}>I understand</ColoredButton>
                </div>
            </Box>
        </Modal>
    )
}

export default RiskDisclosurePopup