import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createAxiosInstance } from "../../Utils/axiosConfig";
import BrokerAccount from "./BrokerAccount";

const BrokerSelectDialog = ({ setBrokerAccountsSelected, setIsPaperTrade, isPaperTrade }) => {
    const [brokerAccountsSetup, setBrokerAccountsSetup] = useState([]);

    useEffect(() => {
        async function getBrokersSetup() {
            try {
                var axiosInstance = createAxiosInstance(true);
                const brokerResponse = await axiosInstance.post('auth/user/brokers/');
                if (brokerResponse.status === 200)
                    setBrokerAccountsSetup(brokerResponse.data);
            } catch (err) {
                // Implement new err mechanism in this page
                console.log(err);
            }
        }
        getBrokersSetup();
    }, [])
    return (
        <>
            <div className="border-b mb-3 border-black">
                <div className="flex mb-3 items-center justify-between p-4 rounded-lg border bg-[#F9F5FF]">
                    <h2 className="text-sm font-medium text-[#293056]">Paper trade</h2>
                    <Checkbox size="small" checked={isPaperTrade} onChange={(e) => setIsPaperTrade(e.target.checked)} />
                </div>
            </div>
            {(brokerAccountsSetup.finvasia?.length > 0 || brokerAccountsSetup.angelist?.length > 0 || brokerAccountsSetup.zerodha?.length) > 0 && 
                <div>
                    {brokerAccountsSetup.finvasia?.map(brokerAccount => {
                        return <BrokerAccount broker='finvasia' key={brokerAccount.id} brokerAccount={brokerAccount} setBrokerAccountsSelected={setBrokerAccountsSelected} />
                    })}
                    {brokerAccountsSetup.angelist?.map(brokerAccount => {
                        return <BrokerAccount broker='angel' key={brokerAccount.id} brokerAccount={brokerAccount} setBrokerAccountsSelected={setBrokerAccountsSelected} />
                    })}
                    {brokerAccountsSetup.zerodha?.map(brokerAccount => {
                        return <BrokerAccount broker='zerodha' key={brokerAccount.id} brokerAccount={brokerAccount} setBrokerAccountsSelected={setBrokerAccountsSelected} /> 
                    })}
                </div>
            }
        </>
    )
}

export default BrokerSelectDialog