import React, { useEffect, useState } from 'react';
import { createAxiosInstance } from '../../Utils/axiosConfig';
import LoadingBackdrop from '../common/LoadingBackdrop';
import BrokerAccountLoginCard from './BrokerAccountLoginCard';


const BrokerLoginDialog = () => {

    const [brokerAccountsSetup, setBrokerAccountsSetup] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getBrokersSetup() {
            setLoading(true);
            try {
                var axiosInstance = createAxiosInstance(true);
                const brokerResponse = await axiosInstance.post('auth/user/brokers/');
                if (brokerResponse.status === 200)
                    setBrokerAccountsSetup(brokerResponse.data);
            } catch (err) {
                // Implement new err mechanism in this page
                console.log(err);
            } finally {
                setLoading(false);
            }
        }
        getBrokersSetup();
    }, [])

    return (
        <div className='flex flex-col gap-2'>
            {(brokerAccountsSetup && (!brokerAccountsSetup['finvasia'] && !brokerAccountsSetup['zerodha'] && !brokerAccountsSetup['angelist'])) && 'No broker accounts setup.'}

            {brokerAccountsSetup && brokerAccountsSetup.finvasia?.length > 0 && 
            brokerAccountsSetup.finvasia.map(finvasiaAccount => {
                return <BrokerAccountLoginCard key={finvasiaAccount.id} brokerAccount={finvasiaAccount} broker='finvasia' />
            })}

            {brokerAccountsSetup && brokerAccountsSetup.angelist?.length > 0 &&
            brokerAccountsSetup.angelist.map(angelistAccount => {
                return <BrokerAccountLoginCard key={angelistAccount.id} brokerAccount={angelistAccount} broker='angelist' />
            })}

            {brokerAccountsSetup && brokerAccountsSetup.zerodha?.length > 0 &&
            brokerAccountsSetup.zerodha.map(zerodhaAccount => {
                return <BrokerAccountLoginCard key={zerodhaAccount.id} brokerAccount={zerodhaAccount} broker='zerodha' />
            })}

            {loading && <LoadingBackdrop loading={loading} />}

        </div>

    )
}

export default BrokerLoginDialog
