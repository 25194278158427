import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("userAuthToken");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  function saveToken(userToken) {
    localStorage.setItem(
      "userAuthToken",
      JSON.stringify({
        userToken,
      })
    );
    setToken(userToken);
  }

  return {
    token,
    setToken: saveToken,
  };
}
