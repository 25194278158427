import { Key, NotificationsNone } from "@mui/icons-material";
import { useState } from "react";
import { IconContext } from "react-icons";
import { LuCreditCard } from "react-icons/lu";
import BrokerLoginDialog from "../components/broker-setup/BrokerLoginDialog";
import ColoredButton from "../components/common/ColoredButton";
import DashboardMasonry from "../components/dashboard/DashboardMasonry";
import DialogBoxWrapper from "../components/common/DialogBoxWrapper";
import RiskDisclosurePopup from "../components/common/RiskDisclosurePopup";
import Notifications from "../components/notifications/Notifications";

function DashboardPage() {

    const displayGreeting = () => {
        var today = new Date();
        var hour = today.getHours();
        
        if (hour < 12) {
           return "Good Morning";
        } else if (hour < 18) {
            return "Good Afternoon";
        } else {
            return "Good Evening";
        }
    }

    const [showLoginDialog, setShowLoginDialog] = useState(false);
    return (
        <div className="flex gap-5">
            <div className="flex flex-col w-full">
                <div className="flex justify-between">
                    <h1 className="font-medium text-4xl">{displayGreeting()}, {JSON.parse(localStorage.getItem("userDetails"))?.first_name === "" ? JSON.parse(localStorage.getItem("userDetails"))?.username : JSON.parse(localStorage.getItem("userDetails"))?.first_name}</h1>

                    <div className="flex gap-3">
                        <Notifications />
                        <ColoredButton variant="contained" size="small" startIcon={<Key fontSize="small" />} onClick={() => setShowLoginDialog(prev => !prev)}>Broker Account Login</ColoredButton>
                    </div>
                </div>
                <DashboardMasonry />

                {showLoginDialog &&
                    <DialogBoxWrapper
                        iconComp={<IconContext.Provider value={{ color: '#3E4784', size: '1.2em' }}><LuCreditCard /></IconContext.Provider>}
                        open={showLoginDialog} setOpen={setShowLoginDialog}
                        title='Login with broker account'
                        subtitle=''
                        contentComponent={<BrokerLoginDialog />}
                        displayActions={false}
                    />
                }
            </div>

            <RiskDisclosurePopup />
        </div>
    );
}

export default DashboardPage;
