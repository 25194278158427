import AddIcon from '@mui/icons-material/Add';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ColoredButton from "../components/common/ColoredButton";
import FeedbackSnackbar from "../components/common/FeedbackSnackbar";
import LoadingBackdrop from '../components/common/LoadingBackdrop';
import NavTab from "../components/common/NavTab";
import EtfStrategiesTable from '../components/list-strategies/EtfStrategiesTable';
import OpenStrategiesTable from "../components/list-strategies/OpenStrategiesTable";
import SavedStrategiesTable from "../components/list-strategies/SavedStrategiesTable";
import { createAxiosInstance } from '../Utils/axiosConfig';

function Strategies() {
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [strategies, setStrategies] = useState({
        openStrategies: [],
        savedStrategies: [],
        etfStrategies: []
    });
    const navigate = useNavigate();

    const navigateToAddStrategy = () => {
        if (activeTab === 0 || activeTab === 1) navigate('addStrategy')
        else if (activeTab === 2) navigate('addStrategy/etf')
    }

    useEffect(() => {

        const controller = new AbortController();

        async function getData() {
            setLoading(true);
            try {
                const axiosInstance = createAxiosInstance(true);
                const response = await axiosInstance.get('trades/strategy/?deep=true', {
                    signal: controller.signal
                });
                if (response.status === 200) {
                    const allStrategies = response.data;
                    const openStrategies = allStrategies.filter((strategy) => strategy.status === "placed" || strategy.status === "stopped" || strategy.status === "pending")
                        .map((strategy) => {
                            return {
                                ...strategy,
                                instrument: strategy.instrument,
                                lots: strategy.trades[0].lots,
                            };
                        })
                    const savedStrategies = allStrategies.map((strategy) => {
                        return {
                            ...strategy,
                            instrument: strategy.instrument,
                            lots: strategy.trades[0]?.lots,
                        };
                    }).sort((a, b) => moment(b.created_at) - moment(a.created_at))
                    setStrategies(prev => ({ ...prev, openStrategies, savedStrategies }));
                }
            } catch (err) {
                setSnackBarOpen(true);
                let msg = '';
                if (err.request.status === 404)
                    msg = err.message;
                else if (err.request.status === 401)
                    msg = err.response.data.detail;
                setDisplayMsg({
                    msg: msg,
                    type: "error",
                });
            } finally {
                setLoading(false);
            }
        }

        (activeTab === 0 || activeTab === 1) && getData();

        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        const fetchEtfStrategies = async () => {
            try {
                setLoading(true);
                const axiosInstance = createAxiosInstance(true);
                const response = await axiosInstance.get('trades/etf/');
                if (response.status === 200) {
                    const etfStrategies = response.data;
                    setStrategies(prev => ({
                        ...prev,
                        etfStrategies
                    }));
                }

            } catch (err) {
                setSnackBarOpen(true);
                let msg = '';
                if (err.request.status === 404)
                    msg = err.message;
                else if (err.request.status === 401)
                    msg = err.response.data.detail;
                setDisplayMsg({
                    msg: msg,
                    type: "error",
                });

            } finally {
                setLoading(false);
            }
        }
        activeTab === 2 && fetchEtfStrategies();
    }, [activeTab])

    if (loading) {
        return (
            <LoadingBackdrop loading={loading} />
        );
    }

    return (
        <div className="h-full flex flex-col">
            <div className="flex justify-between">
                <h1 className="text-4xl font-medium text-gray-900 tracking-tight">Strategies</h1>
                <ColoredButton variant="contained" size="small" startIcon={<AddIcon />} onClick={navigateToAddStrategy}>Add Strategy</ColoredButton>
            </div>


            <div className='flex gap-2 mt-8'>
                <NavTab tabId={0} isActive={activeTab === 0} title="Open strategies" chipText={`${strategies.openStrategies.length} open strategies`} setActiveTab={setActiveTab} />
                <NavTab tabId={1} isActive={activeTab === 1} title="Saved strategies" setActiveTab={setActiveTab} />
                <NavTab tabId={2} isActive={activeTab === 2} title="ETF strategies" setActiveTab={setActiveTab} />
            </div>

            {moment().isAfter(moment('3:30pm', 'h:mma')) && <h1 className="mt-4 text-sm text-red-600 font-semibold" id="blink_text">The market is now closed!!</h1>}

            <div className="mt-6 flex-auto">
                {activeTab === 0 ? <OpenStrategiesTable openStrategies={strategies.openStrategies} />
                    : activeTab === 1 ? <SavedStrategiesTable savedStrategies={strategies.savedStrategies} />
                        : <EtfStrategiesTable etfStrategies={strategies.etfStrategies} />
                }
            </div>

            {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />}
        </div>
    )
}

export default Strategies;
